import React, { useState, useEffect, useRef } from "react";

import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { Carousel as ReactCarousel } from "primereact/carousel";
import Carousel from "react-elastic-carousel";
// import { Skeleton } from 'primereact/skeleton';


import ModalVideo from "react-modal-video";
import * as api from '../../../api/susfApi';
// redux ================>
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from "../../../redux/actions/homeActions";
import { format } from "date-fns";
import { arSA } from 'date-fns/locale'

import { Link } from "react-router-dom";
import DocViewer, { BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer } from "react-doc-viewer";
import { Dialog } from 'primereact/dialog';


import AOS from 'aos';


// import { Viewer,Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// import '@react-pdf-viewer/core/lib/styles/index.css';
//  import '@react-pdf-viewer/default-layout/lib/styles/index.css';




// import LinesEllipsis from "react-lines-ellipsis";
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';



// End redux ============>
function Homepage(props) {
  const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);
  //  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    if (window.innerWidth > 900) {
      //require('aos/dist/aos.css');
    }

    window.scrollTo(0, 0);
    // AOS.init(
    //   {
    //     disable: function () {
    //       var maxWidth = 800;
    //       return window.innerWidth < maxWidth;
    //     }
    //   }
    // );

    if (!props.slider || props.slider.length <= 0) {
      props.getSlider();
      props.getLatestNews("limit=6&moduleNumber=1");
      props.getLatestEvents("limit=6&moduleNumber=2");
      props.getMediaCenter("limit=6");
      props.getLinksForms();
      props.getPublication("limit=6");
      props.getAds("limit=3");
      props.getCompetions();
      props.getLatestMatchesResult();
      props.getHoner();
    }
  }, [])

  const [downloadapp, setDownloadApp] = useState(false);
  const [downloadIOS, setDownloadIOS] = useState("");
  const [downloadAndroid, setDownloadAndroid] = useState("");
  const [videoIdStatic, setVideioId] = useState("");
  const [presidentSpech, setPresidentSpech] = useState("");
  useEffect(() => {
    if (props.sharedData) {
      setDownloadApp(props.sharedData.downloadapp);
      setDownloadIOS(props.sharedData.ios);
      setDownloadAndroid(props.sharedData.android);
      setVideioId(props.sharedData.videoId);
      setPresidentSpech(props.sharedData.presidentSpech);
    }
  }, [props.sharedData]);

  // useEffect(() => {
  //   console.clear()
  //   console.log(props.home.home.slider)

  // }, [props.home])

  const [isOpen, setisOpen] = useState(false);
  const openModal = () => {
    setisOpen(!isOpen);
  };

  // Define Video Id to play video
  const [videoID, setVideoID] = useState('');
  const playVideo = (id) => {
    if (id.videoIdStatic)
      setVideoID(id.videoIdStatic);
    else
      setVideoID(id);

    setisOpen(!isOpen);
  }

  // Define Media Center Tabs
  const [mediaTabSelected, setMediaTabSelected] = useState('img');

  // Define Matches Tabs
  const [matchesTabSelected, setMatchesTabSelected] = useState('matches');

  // Main Slider Content
  const content = props.slider ? props.slider : [];
  // End Main Slider Content

  // NewsTicker Sider Settings
  const newsTicker = props.sharedData?.newsTicker ?
    (props.sharedData?.newsTicker.map((elem) => {
      return { id: Math.random(), title: elem }
    })) : [];

  const newsTickerResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const newsTickerTemplate = (item) => {
    return (
      <div className="newsTicker-item">
        <div className="newsTicker-item-content">
          <p>
            <span>{item.title}</span>
            {/* <span>{item.date}</span> */}
          </p>
        </div>
      </div>
    );
  };
  // End NewsTicker Sider Settings

  // News Sider Settings
  const news = props.latestNews && props.latestNews.length > 0 ? (
    props.latestNews
      .filter((menu) =>
        menu.moduleNumber === 1)
      .map(x => {
        return {
          id: x.id,
          title: x.title,
          description: x.bodyDecs.replace(/<img .*?>/g, ""),//.length > 220 ? x.bodyDecs.substring(0, 220) : x.bodyDecs,
          image: x.image
        };
      })) : [];

  const newsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const newsTemplate = (item) => {
    return (
      <div className="news-item">
        <div className="new-item-content">
          <div className="img">
            <img
              src={item.image}
              alt={item.name}
              className="new-image"
            />
          </div>
          <div className="content">
            <h4 >
              <ResponsiveEllipsis
                unsafeHTML={item.title}
                maxLine='2'
                ellipsis=''
              />
              {/* className="climp num2" {item.title} */}
            </h4>
            {/* climp num3  dangerouslySetInnerHTML={{ __html: item.description }}*/}
            <h6 className="text-justify" >
              <ResponsiveEllipsis
                unsafeHTML={item.description}
                maxLine='3'
                ellipsis='...'
              />
            </h6>
            <Link to={`/details/${item.id}`} >
              <button className="btn btn-dafault">
                {/* onPress={() =>
              navigate('details', { id: item.id })
            } */}
                <img src="./content/images/icons/left-arrow.svg" alt="" />
                المزيد
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  };
  // End News Sider Settings

  // Events Sider Settings
  const events = props.latestEvents && props.latestEvents.length > 0 ? (
    props.latestEvents
      .filter((menu) =>
        menu.moduleNumber === 2)
      .map(x => {
        return {
          id: x.id,
          title: x.title,
          //description: x.bodyDecs.length > 70 ? x.bodyDecs.substring(0, 70) : x.bodyDecs,
          image: x.image,
          date: x.eventDate //"Jul 18, 2021",
          // time: "10:00 pm - 11:45 pm",
        };
      })) : [];

  const eventsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 3,
      numScroll: 1,
    },
  ];

  const eventsTemplate = (event) => {
    return (
      <Link to={`/details/${event.id}`} >
        <div className="event-item">
          <div className="event-item-content clearfix">
            <div className="left">
              <img
                src={event.image}
                alt={event.name}
                className="event-image"
              />
            </div>
            <div className="right">
              <span className="shortDate">
                {format(new Date(event.date), "dd", { locale: arSA })}
                <small>
                  {format(new Date(event.date), "MMMM", { locale: arSA })}
                </small>
              </span>
              {/* className="climp num2" */}
              <h3 >
                <ResponsiveEllipsis
                  unsafeHTML={event.title}
                  maxLine='2'
                  ellipsis='...'
                />

              </h3>
              <p >
                <span>
                  {format(new Date(event.date), "dd MMMM, yyyy - hh:mm a", { locale: arSA })}
                </span>
                {/* <span>
                {format(new Date(event.date), "hh:mm a", { locale: arSA })}
              </span> */}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  };
  // End Events Sider Settings

  // Adds Slider Settings
  const adds = props.ads && props.ads.length > 0 ?
    (props.ads.map((elem) => {
      return { id: Math.random(), image: elem.image, url: elem.url }
    })) : [];

  const addsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const addsTemplate = (add) => {
    return (
      <div className="adds-item">
        <div className="adds-item-content">
          <div className="img">
            <a href={add.url} target="_blank">
              <img
                src={add.image}
                alt="adds"
                className="new-image"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };
  // End Adds Slider Settings

  // Prints Slider Settings
  const prints = props.publication && props.publication.length > 0 ?
    (props.publication.map((elem) => {
      return { id: Math.random(), image: elem.image, name: elem.name, attachKey: elem.attachKey, attachName: elem.attachName }
    })) : [];

  const printsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const printsTemplate = (add) => {
    return (
      <div className="prints-item">
        <div className="prints-item-content">
          <div className="overlay">
            {/* <h3>كرة القدم</h3> */}
            <h4>{add.name}</h4>

            <a href="#!" className="downloadPdf" onClick={() => viewDoc(add.attachKey, add.attachName)}>
              <img
                src="/content/images/icons/download.svg"
                alt="تحميل"
              />
            </a>

          </div>
          <div className="img">
            <img
              src={add.image}
              alt="prints"
              className="new-image"
            />
          </div>
        </div>
      </div>
    );
  };
  // End Prints Slider Settings

  // Links Forms data
  const linksForms = props.linksForms && props.linksForms.length > 0 ? (
    props.linksForms
      .map(x => {
        return {
          id: x._id,
          name: x.name,
          image: x.image,
          link: x.link,
          attachName: x.attachName,
          attachKey: x.attachKey
        };
      })) : [];



  const downloadFile = (attachKey, attachName) => {
    api.getFile(attachKey, attachName, "publication").then((response) => {
      window.open(response.data?.url);
    });
  }
  // End Links Forms data

  // Sports Slider Settings
  const sports = props.competion && props.competion.length > 0 ?
    (
      props.competion
        .map(x => {
          return {
            id: x._id,
            title: x.name,
            icon: x.image,
            season: x.season
          };
        })
    ) : [];


  const sportsBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4 }
  ]
  // End Sports Slider Settings


  // Honor Board Slider Settings
  const honors = props.honer && props.honer.length > 0 ?
    props.honer
    :
    [

    ];

  const honorsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const honorsTemplate = (honor) => {
    return (
      <div className="honor-item">
        <div className="event-item-content">
          <div className="img">
            <img
              src={honor.image}
              alt={honor.name}
              className="new-image"
            />
          </div>
          <div className="content">
            <h4>{honor.title}</h4>
            <h5>{honor.name}</h5>
            <h6>{honor.university}</h6>
          </div>
        </div>
      </div>
    );
  };
  // End Honor Board Slider Settings

  // Media Center [Photos, Video, Journals]
  const photoList = props?.mediaCenter?.images;
  const videoList = props?.mediaCenter?.vidios;
  const journalsList = props?.mediaCenter?.newspaper;

  const [docs, setDocViewer] = useState([]);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const viewDoc = (attachKey, attachName) => {
    api.getFile(attachKey, attachName, "publication").then((response) => {
      // setDocViewer(response.data?.url);//);
      setDocViewer([{ uri: response.data?.url }]);
      setDisplayResponsive(true);
    });
  }
  const onHide = () => {
    setDisplayResponsive(false);
  }

  // Latest matches & results
  const [latestMatches, setLatestMatch] = useState([]);
  const [latestResult, setLatestResult] = useState([]);

  useEffect(() => {
    if (props.latestMatchesResult) {
      setLatestMatch(props.latestMatchesResult.matches
        .map(x => {
          return {
            id: x.matchTeam.id,
            team1Name: x.matchTeam.team1Name,
            team1Logo: x.matchTeam.team1Logo,
            team2Name: x.matchTeam.team2Name,
            team2Logo: x.matchTeam.team2Logo,
            matchDate: x.matchTeam.dateTime,
            sportName: x.matchTeam.sportName,
            sportLogo: x.matchTeam.sportLogo,
          };
        }));

      setLatestResult(props.latestMatchesResult.resultData
        .map(x => {
          return {
            id: x.matchTeam.id,
            team1Name: x.matchTeam.team1Name,
            team1Logo: x.matchTeam.team1Logo,
            team2Name: x.matchTeam.team2Name,
            team2Logo: x.matchTeam.team2Logo,
            matchDate: x.matchTeam.dateTime,
            sportName: x.matchTeam.sportName,
            sportLogo: x.matchTeam.sportLogo,
            team1Score: x.matchTeam.scoreVisible ? x.matchTeam.team1Score : '-',
            team2Score: x.matchTeam.scoreVisible ? x.matchTeam.team2Score : '-',
          };
        }));
    }
  }, [props.latestMatchesResult]);
  
  // const viewer = useRef(null);

  // useEffect(() => {
  //   WebViewer(
  //     {
  //       path: '/webviewer/lib',
  //       initialDoc: '/files/1.pdf',
  //     },
  //     viewer.current,
  //   ).then((instance) => {
  //       // now you can access APIs through the WebViewer instance
  //       const { Core } = instance;

  //       // adding an event listener for when a document is loaded
  //       Core.documentViewer.addEventListener('documentLoaded', () => {
  //         console.log('document loaded');
  //       });

  //       // adding an event listener for when the page number has changed
  //       Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
  //         console.log(`Page number is: ${pageNumber}`);
  //       });
  //     });
  //   }, []);

  return (
    <>
      <Dialog header="مستعرض الملفات" visible={displayResponsive} draggable={false} maximized={true} onHide={() => onHide()} breakpoints={{ '960px': '90vw' }} style={{ width: '90vw', height: '90vh' }} >
        {/* <Viewer
    fileUrl='/files/1.pdf'
    // plugins={[
    //     // Register plugins
    //     defaultLayoutPluginInstance,
    // ]}
/> */}

        {/* <div className="MyComponent">
          <div className="header">React sample</div>
          <div className="webviewer" ref={viewer}></div>
        </div> */}
        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.js">
        <div style={{ height: '750px' }}>
          <Viewer fileUrl={`${process.env.PUBLIC_URL}/files/pdf-open-parameters.pdf`} />
        </div>
        </Worker>  */}

        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}

        {/* </Worker>  */}
        {/* <iframe src={docs} className="iframe"></iframe> */}
        {/* "http://localhost:3110/publication/f-ee9e1368-4b6e-4093-9f67-ecac4e8c2603.pdf" */}
        <DocViewer config={{ header: { disableHeader: true, disableFileName: true, retainURLParams: false } }}
          documents={docs} pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer]} style={{ width: '85vw', height: '72vh' }} />
      </Dialog>

      <div className="homepage">
        <div className="mainSLider">
          <Slider className="slider-wrapper" infinite={true} autoplay={4000}>
            {content.map((item, index) => (
              <div key={index} className="slider-content">
                <div className="slider-overlay">
                  <div className="inner">
                    <div className="left">
                      <h3 className="title">
                        {item.title}
                      </h3>
                      <p className="meta-date">
                        {format(new Date(item.publishDate), "yyyy-MM-dd", { locale: arSA })}
                      </p>
                      {/* <p className="description text-justify climp num5" dangerouslySetInnerHTML={{ __html: item.bodyDecs.replace(/<img .*?>/g, "") }}> */}
                      <p className="description text-justify"  >
                        <Link to={`/details/${item.id}`} >
                          <ResponsiveEllipsis
                            unsafeHTML={item.bodyDecs.replace(/<img .*?>/g, "")}
                            maxLine='5'
                            ellipsis='...'
                          />
                        </Link>
                      </p>
                    </div>
                    <div className="right">
                      <img src={item.image} alt="img" />
                      <Link to={`/details/${item.id}`} >  <button className="btn">المزيد</button> </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {
          (newsTicker.length > 0 ? (
            <>
              <div className="newsticker">
                <img src="./content/images/icons/live.svg" alt="" />
                <h3>الأخبار</h3>
                <ReactCarousel
                  value={newsTicker}
                  itemTemplate={newsTickerTemplate}
                  numVisible={1}
                  numScroll={1}
                  responsiveOptions={newsTickerResponsiveOptions}
                  autoplayInterval={4000}
                  circular={true}
                ></ReactCarousel>
              </div>
            </>
          ) : ""
          )
        }

        <div className="electronicServices">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-xl-4">
                <div data-aos="zoom-in" data-aos-duration="500">
                  <h3 className="main-title">الخدمات الإلكترونية</h3>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 col-xl-8">
                <div className="services">
                  <div data-aos="fade-right" data-aos-duration="500">
                    <div className="service-box" onClick={() => window.open("https://nashtsport.com", "_blank")}>
                      <img src="./content/images/icons/tracking.svg" alt="نشط" />
                      بوابة نشط
                    </div>
                  </div>
                  <div data-aos="fade-down" >
                    <div className="service-box" onClick={() => window.open("https://admin.susfweb.com/login?lt=2", "_blank")}>
                      <img src="./content/images/icons/trophy.svg" alt="المسابقات" />
                      بوابة المسابقات
                    </div>
                  </div>
                  <div data-aos="fade-left" data-aos-duration="500">
                    <div className="service-box" onClick={() => window.open("https://admin.susfweb.com/login?lt=1", "_blank")}>
                      <img src="./content/images/icons/skills.svg" alt="الأهلية" />
                      بوابة الأهلية
                    </div>
                  </div>
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <div className="service-box" onClick={() => window.open("https://outlook.office.com/owa/?realm=susfweb.com", "_blank")}>
                      <img src="./content/images/icons/envelop.svg" alt="البريد الألكترونى" />
                      البريد الإلكتروني
                    </div>
                  </div>
                  <div data-aos="fade-up" >
                    <div className="service-box" onClick={() => window.open("https://susftask.com", "_blank")}>
                      <img src="./content/images/icons/to-do-list.svg" alt="إدارة المهام" />
                      نظام إدارة المهام
                    </div>
                  </div>
                  <div data-aos="fade-left" data-aos-duration="1000">
                    <div className="service-box" onClick={() => window.open("https://admin.susfweb.com/login?lt=3", "_blank")}>
                      <img src="./content/images/icons/chart.svg" alt="المنتخبات والنتائج" />
                      بوابة المنتخبات والنتائج
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="presidentWord">
          <div className="overlay">
            <div className="container">
              <div className="left">
                <img src="./content/images/president.PNG" alt="" width="267px" />
              </div>
              <div className="right">
                <h3>كلمة رئيس الإتحاد</h3>
                <p className="climp num4">
                  <Link to={`/details/ps`} >
                    {presidentSpech}
                  </Link>
                  &nbsp;
                  <Link to={`/details/ps`} >
                    المزيد
                  </Link>

                  {/* يسعى الاتحاد الرياضي للجامعات السعودية لبناء نموذج وطني للرياضة الجامعية متوائم مع أفضل الممارسات الدولية، بما يسمح له من تحقيق أهداف رؤية المملكة المرتبطة بالنشاط البدني والرياضي، وذلك من خلال رياضة جامعية يرتفع فيها نسبة المشاركين بمختلف قدراتهم ومستوياتهم وأهدافهم، وتتحقق فيها عدالة الفرص بين الجنسين وبين الممارسين، ويتحقق من خلالها تمثيل دولي يلائم مكانة وإمكانات المملكة، لتكون الرياضة الجامعية من أكبر الداعمين لرياضة الوطن. ومن هذا المنطلق يقوم الاتحاد حالياً بتنفيذ حزمة من المشاريع والبرامج التطويرية لتمكين بيئة الرياضة الجامعية من تحقيق هذه الأهداف الاستراتيجية. */}
                </p>
                {/* <p>
                  تحت رعاية وزير التعليم رئيس مجلس إدارة الاتحاد الرياضي
                  للجامعات السعودية الدكتور حمد بن محمد آل الشيـــخ وبحضـــور
                  رئيس جامعـــــــة حائل الدكتور خليـــــل بن إبراهيم
                  البراهيم،مع رئيس الاتحاد الرياضي للجامعات السعودية الدكتور
                  خالد بن صالح المزيني مع ورئيس الاتحاد السعودي لكرة
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="latestNews">
          <div className="title">
            <div data-aos="zoom-in-up" data-aos-duration="500">
              <h3>أحدث الأخبار</h3>
            </div>
          </div>
          <ReactCarousel
            value={news}
            itemTemplate={newsTemplate}
            numVisible={3}
            numScroll={1}
            responsiveOptions={newsResponsiveOptions}
            autoplayInterval={4000}
            circular={false}
          ></ReactCarousel>
        </div>
        <div className="about-union">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-9">
                <h3>عن الإتحاد</h3>
                <div data-aos="zoom-out">
                  <p>
                    تأسس الاتحاد الرياضي للجامعات السعودية بناءً على قرار
                    مجلس التعليم العالى رقم 6/57/1431 هـ وتاريخ 17/1/1431 هـ
                    المتوّج بموافقة خادم الحرمين الشريفين رئيس مجلس التعليم العالى رقم 4644 / م ب
                    وتاريخ 28/5/1431 هـ.
                    {/* 
                  شهدت المملكة العربية السعودية في السنوات الأخيرة تطور شامل في
                  جميع مجالات الحياة، وخاصة في مجال التعليم العالي، فمنذ تولى
                  خادم الحرمين الشريفين مقاليد الحكم إزداد عدد الجامعات والكليات
                  الحكومية والأهلية بشكل متسارع ، حيث قفز عدد الجــامعــــات
                  الحكـــوميــــــة إلى أكثر من (24) جامعة تخدم أكثر من (950000)
                  طـالـبـــاً، وأكــثــــر من (28) جامعة وكلية أهلية تخدم أكثر
                  من (100000) طالب */}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="playButton">
                  <div>
                    <button className="btn btn-primary" onClick={() => playVideo({ videoIdStatic })}>
                      <img src="./content/images/icons/play.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="media-center">
          <div className="container">
            <div className="title">
              <div data-aos="zoom-in-up" data-aos-duration="500">
                <h3>المركز الإعلامي</h3>
              </div>
            </div>
            <div className="tabs">
              <div className="tabs-header">
                <a role="button" className={mediaTabSelected === 'img' ? 'active' : ''}
                  onClick={() => setMediaTabSelected('img')}>
                  الصور
                </a>
                <a role="button" className={mediaTabSelected === 'video' ? 'active' : ''}
                  onClick={() => setMediaTabSelected('video')}>
                  الفيديوهات
                </a>
                <a role="button" className={mediaTabSelected === 'journal' ? 'active' : ''}
                  onClick={() => setMediaTabSelected('journal')}>
                  اخبار الصحف
                </a>
              </div>
              <div className="tabs-body">
                <div className="row">
                  {mediaTabSelected === 'img' && photoList && photoList.length > 0 ? (
                    <>
                      <div data-aos="flip-down">
                        {photoList.map(photo => (
                          <div className="col-sm-12 col-md-6 col-lg-4" key={photo._id}>
                            <div className="media-box">
                              <img
                                src={photo.image}
                                alt="main-img"
                                className="main-img"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : ''}
                  {mediaTabSelected === 'video' && videoList && videoList.length > 0 ? (
                    <>
                      <div data-aos="flip-down">
                        {videoList.map(video => (
                          <div className="col-sm-12 col-md-6 col-lg-4" key={video._id}>
                            <div className="media-box" onClick={() => playVideo(video.url)}>
                              <img
                                src={video.image}
                                alt="main-img"
                                className="main-img"
                              />
                              <img
                                src="./content/images/icons/play_outline.svg"
                                alt="play"
                                className="play-img"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : ''}
                  {mediaTabSelected === 'journal' && journalsList && journalsList.length > 0 ? (
                    <>
                      <div data-aos="flip-down">
                        {journalsList.map(file => (
                          <div className="col-sm-12 col-md-6 col-lg-6" key={file.id}>
                            <div className="file-box">
                              <a href={file.newsLink} target="_blank">
                                <img src="./content/images/icons/ext-link.svg" alt="link" />
                              </a>
                              <img
                                src={file.image}
                                alt="main-img"
                                className="main-img"
                              />
                              <div className="info">
                                <Link to={`/details/${file.id}`} >
                                  <h3>{file.title}</h3>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : ''}

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="events-competitions">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div data-aos="fade-up" data-aos-duration="2000"
                  data-aos-anchor-placement="top-bottom">
                  <div className="events">
                    <div className="title">
                      <h3>
                        الفعاليات
                        <img src="./content/images/icons/events.svg" alt="" />
                      </h3>
                      <h5>
                        &nbsp;
                        {/* يمكنك سرد الأحداث الخاصة بك مع نظام إدارة الأحداث المتقدم */}
                      </h5>
                    </div>

                    {/* <div className="card">
                    <div className="p-grid p-formgrid">
                      <div className="p-field p-col-12 p-md-6">
                        <h5>Rectangle</h5>
                        <Skeleton className="p-mb-2"></Skeleton>
                        <Skeleton width="10rem" className="p-mb-2"></Skeleton>
                        <Skeleton width="5rem" className="p-mb-2"></Skeleton>
                        <Skeleton height="2rem" className="p-mb-2"></Skeleton>
                        <Skeleton width="10rem" height="4rem"></Skeleton>
                      </div>
                    </div>
                  </div> */}
                    {/* <Skeleton className="p-mb-2" height="35rem"></Skeleton> */}
                    <ReactCarousel
                      value={events}
                      itemTemplate={eventsTemplate}
                      numVisible={3}
                      numScroll={1}
                      responsiveOptions={eventsResponsiveOptions}
                      orientation="vertical"
                      autoplayInterval={4000}
                    // circular={true}
                    ></ReactCarousel>
                  </div>
                  <div className="readMore">
                    <Link to={`/events`} >شاهد المزيد من الأحداث</Link>
                    {/* <a href="">شاهد المزيد من الأحداث</a> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div data-aos="fade-up" data-aos-duration="2000"
                  data-aos-anchor-placement="top-bottom">
                  <div className="competitions">
                    <div className="title">
                      <h3>
                        <img src="./content/images/icons/competiton.svg" alt="" />
                        المسابقات
                      </h3>
                    </div>

                    <div className="comps">
                      {/* <ul className="list-unstyled clearfix">
                      <li>
                        <img
                          src="./content/images/icons/football-white.svg"
                          alt=""
                        />
                        كرة القدم
                      </li>
                      <li>
                        <img
                          src="./content/images/icons/football-white.svg"
                          alt=""
                        />
                        تنس طاولة
                      </li>
                      <li>
                        <img
                          src="./content/images/icons/football-white.svg"
                          alt=""
                        />
                        كرة السلة
                      </li>
                      <li>
                        <img
                          src="./content/images/icons/football-white.svg"
                          alt=""
                        />
                        كرة الطائرة
                      </li>
                    </ul> */}
                      <div className="sports">
                        <Carousel itemsToShow={3} isRTL={true} enableAutoPlay={true}
                          pagination={true} showArrows={false}
                        >
                          {sports.map((item, index) => (
                            <Link to={`/competitions/${item.id}/${item.season}`} key={index} >
                              <div className={`sport-item`}
                                key={item.id}>
                                <div className="icon">
                                  <img src={item.icon} alt="img" />
                                </div>
                                <h6>{item.title}</h6>
                              </div>
                            </Link>
                          ))}
                        </Carousel>
                      </div>
                      <div className="readMore">
                        <Link to={`/competitions`} >
                          المزيد
                        </Link>

                      </div>
                    </div>

                    <div className="questainers">
                      <div className="head">
                        <img src="./content/images/icons/survey.svg" alt="" />
                        استبانات ونماذج
                      </div>
                      <ul className="list-unstyled">
                        {
                          linksForms.length > 0 ? (
                            <>
                              {
                                linksForms.map(item => (
                                  <li key={item.name}>
                                    <div className="left">
                                      {item.name}

                                    </div>
                                    <div className="right">
                                      {(item.link ? (
                                        <>
                                          <a href={item.link} target="_blank">
                                            <img src="/content/images/icons/link.svg" alt="رابط" />
                                          </a>
                                        </>
                                      ) : ''
                                      )}

                                      {(!item.link ? (
                                        <>
                                          <a href="#!" onClick={() => downloadFile(item.attachKey, item.attachName)}>
                                            <img
                                              src="/content/images/icons/download.svg"
                                              alt="تحميل"
                                            />
                                          </a>
                                        </>
                                      ) : ''
                                      )}

                                    </div>
                                  </li>
                                ))
                              }
                            </>
                          ) :
                            (
                              <>
                                <div className="emptyData">
                                  <img src="/content/images/nodata.png" alt="no data" />
                                  <span>لا يوجد</span>
                                </div>
                              </>
                            )
                        }
                      </ul>
                    </div>
                    {/* <div className="readMore">
                  <a href="">شاهد المزيد من الأحداث</a>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="adds-prints">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div data-aos="zoom-in" data-aos-duration="1000">
                  <div className="adds">
                    <div className="title">
                      <h3>
                        الإعلانات
                        <img src="./content/images/icons/adds.svg" alt="" />
                      </h3>
                      <h5>
                        لطلب الخدمة
                        <Link to={`/support/ads`} >
                          إضغط هنا
                        </Link>
                      </h5>
                    </div>
                    <ReactCarousel
                      value={adds}
                      itemTemplate={addsTemplate}
                      numVisible={1}
                      numScroll={1}
                      responsiveOptions={addsResponsiveOptions}
                      autoplayInterval={4000}
                      circular={true}
                    ></ReactCarousel>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div data-aos="zoom-in" data-aos-duration="1000">
                  <div className="prints">
                    <div className="title">
                      <h3>
                        المطبوعات
                        <img src="./content/images/icons/prints.svg" alt="" />
                      </h3>
                      <h5>
                        &nbsp;
                        {/* يمكنك سرد الأحداث الخاصة بك مع نظام إدارة الأحداث المتقدم */}
                      </h5>
                    </div>
                    <ReactCarousel
                      value={prints}
                      itemTemplate={printsTemplate}
                      numVisible={1}
                      numScroll={1}
                      responsiveOptions={printsResponsiveOptions}
                      autoplayInterval={4000}
                      circular={true}
                    ></ReactCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {downloadapp ? (
          <>
            <div className="download-app">
              <div className="container">
                <h3>تحميل التطبيق</h3>
                <div className="divider"></div>
                <p>
                  هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً
                </p>
                <div className="links">
                  <a href={downloadAndroid}>
                    GOOGLE PLAY
                    <img src="./content/images/icons/googleplay.svg" alt="download" />
                  </a>
                  <a href={downloadIOS}>
                    APP STORE
                    <img src="./content/images/icons/appstore.svg" alt="download" />
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : ''}

        <div className="statistics">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="statistics-left clearfix">
                  <div className="box">
                    <img src="./content/images/icons/building.svg" alt="" />
                    <p>
                      <span>{props.sharedData?.universityCount}</span>
                      <span>الجامعات</span>
                    </p>
                  </div>
                  <div className="box">
                    <img
                      src="./content/images/icons/soccer-player-man.svg"
                      alt=""
                    />
                    <p>
                      <span>{props.sharedData?.studentMaleCount}</span>
                      <span>الطلاب</span>
                    </p>
                  </div>
                  <div className="box">
                    <img
                      src="./content/images/icons/soccer-player-women.svg"
                      alt=""
                    />
                    <p>
                      <span>{props.sharedData?.studentFemaleCount}</span>
                      <span>الطالبات</span>
                    </p>
                  </div>
                  <div className="box">
                    <img
                      src="./content/images/icons/weather-forecast.svg"
                      alt=""
                    />
                    <p>
                      <span>{props.sharedData?.seassonsCount}</span>
                      <span>المواسم</span>
                    </p>
                  </div>
                  <div className="box">
                    <img src="./content/images/icons/winner.svg" alt="" />
                    <p>
                      <span>{props.sharedData?.competionCount}</span>
                      <span>المسابقات</span>
                    </p>
                  </div>
                  <div className="box">
                    <img
                      src="./content/images/icons/international.svg"
                      alt=""
                    />
                    <p>
                      <span>{props.sharedData?.internationalCompetionCount}</span>
                      <span>المسابقات الدولية</span>
                    </p>
                  </div>
                </div>
                <div className="union">
                  <img src="./content/images/icons/union.svg" alt="" />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="statistics-right">
                  <div className="wrapper-head">
                    <a role="button" className={matchesTabSelected === 'matches' ? 'active' : ''}
                      onClick={() => setMatchesTabSelected('matches')}>
                      احدث المباريات
                    </a>
                    <a role="button" className={matchesTabSelected === 'results' ? 'active' : ''}
                      onClick={() => setMatchesTabSelected('results')}>
                      احدث النتائج
                    </a>
                  </div>
                  <div className="wrapper-body">
                    {matchesTabSelected === 'matches' ? (
                      <div data-aos="flip-left">
                        <div className="tabOne">
                          {latestMatches.length > 0 ? (
                            <>
                              <ul className="list-unstyled">
                                {
                                  latestMatches.map((item, index) => (
                                    <>
                                      <li key={index}>
                                        <div className="box-head">
                                          <div className="left">
                                            <img src={item.team1Logo} alt="" />
                                            <div className="title">  {item.team1Name}</div>
                                          </div>
                                          <span className="vs">vs</span>
                                          <div className="right">
                                            <div className="title"> {item.team2Name}</div>
                                            <img src={item.team2Logo} alt="" />
                                          </div>
                                        </div>
                                        <div className="box-body">
                                          <div className="left">
                                            {/* <img
                                            src="./content/images/icons/location.svg"
                                            alt=""
                                          /> */}
                                            {item.sportName}
                                          </div>
                                          <div className="middle">
                                            <img src={item.sportLogo} alt="" />
                                          </div>
                                          <div className="right">

                                            {/* <img
                                            src="./content/images/icons/football-player.svg"
                                            alt=""
                                          /> */}
                                            <span>
                                              <strong>
                                                {format(new Date(item?.matchDate), "dd MMMM", { locale: arSA })}
                                              </strong>
                                              {/* <small>
                                              {format(new Date(item?.matchDate), "hh:mm a", { locale: arSA })}

                                            </small> */}
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  ))
                                }
                              </ul>
                            </>
                          ) : (
                            <>
                              <div className="emptyData">
                                <img src="/content/images/nodata.png" alt="no data" />
                                <span>لا يوجد</span>
                              </div>
                            </>
                          )

                          }
                        </div>
                      </div>
                    ) : ''}

                    {matchesTabSelected === 'results' ? (
                      <div data-aos="flip-left">
                        <div className="tabTwo">
                          {latestResult.length > 0 ? (
                            <>
                              <ul className="list-unstyled">
                                {latestResult.map((item, index) => (
                                  <>
                                    <li key={index}>
                                      <div className="box-head">
                                        <div className="left">
                                          <img src={item.team1Logo} alt="" />
                                          <div className="title"> {item.team1Name}</div>
                                        </div>
                                        <span className="vs">vs</span>
                                        <div className="right">
                                          <div className="title">{item.team2Name}</div>

                                          <img src={item.team2Logo} alt="" />
                                        </div>
                                      </div>
                                      <div className="box-body">
                                        <div className="left">

                                          {item.sportName}
                                        </div>
                                        <div className="middle">
                                          {item.team2Score}
                                          :
                                          {item.team1Score}
                                        </div>
                                        <div className="right">
                                          <img src={item.sportLogo} alt="" />
                                          <span>
                                            <strong> {format(new Date(item?.matchDate), "dd MMMM", { locale: arSA })}</strong>
                                            {/* <small>{format(new Date(item?.matchDate), "hh:mm a", { locale: arSA })}</small> */}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <>
                              <div className="emptyData">
                                <img src="/content/images/nodata.png" alt="no data" />
                                <span>لا يوجد</span>
                              </div>
                            </>
                          )
                          }
                        </div>
                      </div>
                    ) : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {honors.length > 0 ? (
          <>
            <div className="honor-board">
              <div className="container">
                <h3>لوحة شرف ( تميز )</h3>
                <ReactCarousel
                  value={honors}
                  itemTemplate={honorsTemplate}
                  numVisible={4}
                  numScroll={1}
                  responsiveOptions={honorsResponsiveOptions}
                  autoplayInterval={4000}
                  circular={true}
                ></ReactCarousel>
              </div>
            </div>
          </>
        ) : ''}

      </div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoID}
        onClose={openModal}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    //  home: state.home,
    slider: state.home.slider,
    latestNews: state.home.latestNews,
    latestEvents: state.home.latestEvents,
    mediaCenter: state.home.mediaCenter,
    sharedData: state.home.sharedData?.sharedData,
    linksForms: state.home.linksforms,
    publication: state.home.publication,
    ads: state.home.ads,
    competion: state.home.competion,
    //latestMatches: state.home.latestMatches,
    //latestResult: state.home.latestResult,
    latestMatchesResult: state.home.latestMatchesResult,
    honer: state.home.honer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // getHome: bindActionCreators(action.getHomeApi, dispatch),
    getSlider: bindActionCreators(action.getHomeApi, dispatch),
    getLatestNews: bindActionCreators(action.getLatestNewsApi, dispatch),
    getLatestEvents: bindActionCreators(action.getLatestEventsApi, dispatch),
    getMediaCenter: bindActionCreators(action.getMediaCenterApi, dispatch),
    getLinksForms: bindActionCreators(action.getLinksFormsApi, dispatch),
    getPublication: bindActionCreators(action.getPublicationApi, dispatch),
    getAds: bindActionCreators(action.getAdsApi, dispatch),
    getCompetions: bindActionCreators(action.getCompetionsApi, dispatch),
    getLatestMatchesResult: bindActionCreators(action.getLatestMatchesResultApi, dispatch),
    getHoner: bindActionCreators(action.getHonerApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
