import { actionType } from "../actionTypes";
import initialState from "./sportsInitialState";

export default function addsReducers(state = initialState, action) {
  switch (action.type) {
    case actionType.GET_SESSONS:
      return { ...state, sessons: action.data };
    default:
      return state;
  }
}
