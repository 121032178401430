import React, { useEffect, useState } from "react";
import SideBar from "../../shared/UI-components/sidebar/sidebar";
import * as action from "../../../redux/actions/homeActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import { arSA } from 'date-fns/locale';
import { Link } from "react-router-dom";

function Events(props: any) {
  const [eventsData, setEventsData] = useState<any>([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.events || props.events.length <= 0) {
      props.getEvents("moduleNumber=2");
    }
  }, [])

  useEffect(() => {
    setEventsData(props.events ? props.events : []);
  }, [props.events])


  return (
    <>
      <div className="events">
        <div className="banner">
          <div className="overlay">
            <h3>الفعاليات</h3>
            <h5>الفعاليات</h5>
          </div>
        </div>
        <div className="page-with-sidebar">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="content">
            <div className="container">
              <div className="row">

                {eventsData ? (
                  <>
                    {eventsData.map((elem: any) => (
                      <>
                        <div className="col-sm-12">
                          <div className="event-box">
                            <div className="left">
                              <img src={elem.image} alt="event" />
                              <div className="date">

                                <span>
                                  {
                                    elem?.eventDate ? (
                                      <>
                                        {format(Date.parse(elem?.eventDate), "dd", { locale: arSA })}
                                      </>
                                    ) : ''
                                  }
                                </span>
                                <span>{
                                  elem?.eventDate ? (
                                    <>
                                      {format(Date.parse(elem?.eventDate), "MMMM", { locale: arSA })}
                                    </>
                                  ) : ''
                                }</span>
                              </div>
                            </div>
                            <div className="right">
                              <Link to={`/details/${elem.id}`} >
                                <h3 className="title climp num2">
                                  {elem.title}
                                </h3>
                              </Link>
                              <div className="meta">
                                <span>
                                  {
                                    elem?.eventDate ? (
                                      <>
                                        {format(Date.parse(elem?.eventDate), "hh:mm a", { locale: arSA })}
                                      </>
                                    ) : ''
                                  }
                                </span>
                                {/* <span>
                                  <img src="./content/images/icons/location-green.svg" alt="calendar" />
                                  جامعة الملك عبد العزيز
                                </span> */}
                              </div>
                              <p className="description climp num4" dangerouslySetInnerHTML={{ __html: elem.bodyDecs.replace(/<img .*?>/g, "") }}>

                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : ''
                }


                {/* <div className="col-sm-12">
                  <div className="event-box">
                    <div className="left">
                      <img src="./content/images/events/event-1.jpg" alt="event" />
                      <div className="date">
                        <span>27</span>
                        <span>February</span>
                      </div>
                    </div>
                    <div className="right">
                      <h3 className="title">
                        جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات
                      </h3>
                      <div className="meta">
                        <span>7:00 - 13:00</span>
                        <span>
                          <img src="./content/images/icons/location-green.svg" alt="calendar" />
                          جامعة الملك عبد العزيز
                        </span>
                      </div>
                      <p className="description">
                        هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="event-box">
                    <div className="left">
                      <img src="./content/images/events/event-2.jpg" alt="event" />
                      <div className="date">
                        <span>18</span>
                        <span>April</span>
                      </div>
                    </div>
                    <div className="right">
                      <h3 className="title">
                        جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات
                      </h3>
                      <div className="meta">
                        <span>7:00 - 13:00</span>
                        <span>
                          <img src="./content/images/icons/location-green.svg" alt="calendar" />
                          جامعة الملك عبد العزيز
                        </span>
                      </div>
                      <p className="description">
                        هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="event-box">
                    <div className="left">
                      <img src="./content/images/events/event-3.jpg" alt="event" />
                      <div className="date">
                        <span>11</span>
                        <span>August</span>
                      </div>
                    </div>
                    <div className="right">
                      <h3 className="title">
                        جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات
                      </h3>
                      <div className="meta">
                        <span>7:00 - 13:00</span>
                        <span>
                          <img src="./content/images/icons/location-green.svg" alt="calendar" />
                          جامعة الملك عبد العزيز
                        </span>
                      </div>
                      <p className="description">
                        هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="event-box">
                    <div className="left">
                      <img src="./content/images/events/event-1.jpg" alt="event" />
                      <div className="date">
                        <span>27</span>
                        <span>February</span>
                      </div>
                    </div>
                    <div className="right">
                      <h3 className="title">
                        جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات
                      </h3>
                      <div className="meta">
                        <span>7:00 - 13:00</span>
                        <span>
                          <img src="./content/images/icons/location-green.svg" alt="calendar" />
                          جامعة الملك عبد العزيز
                        </span>
                      </div>
                      <p className="description">
                        هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.
                      </p>
                    </div>
                  </div>
                </div>
             */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


function mapStateToProps(state: any) {
  return {
    events: state.home.events,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getEvents: bindActionCreators(action.getEventsApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);