import { combineReducers } from "redux";

import home from "./homeReducers";
import sport from "./sportsRedusers";

const rootReducer = combineReducers({
  home,
  sport
});
export default rootReducer;
