import React, { useEffect, useState } from "react";
import SideBar from "../../shared/UI-components/sidebar/sidebar";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { format } from "date-fns";
import { arSA } from 'date-fns/locale'

import * as api from "../../../api/susfApi";
function DetailsPage(props: any) {
  const { id }: any = useParams();
  const [content, setData] = useState<any>();
  const [isPSData, setPSFlag] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    let tempData;
    //console.log(id)
    if (id === "ps") {
      setPSFlag(true);
      tempData = {
        title: 'كلمة رئيس الاتحاد',
        bodyDecs: api.getPresidentSpeach(),
        image: '/content/images/president.PNG'
      };
    } else {

      if (props.slider && props.slider.length > 0) {
        tempData = props.slider.find((element: any) => {
          return element.id === id;
        });
        // setData(props.slider.find((element: any) => {
        //   return element.id === id;
        // }));
      }

      if (props.latestNews && props.latestNews.length > 0) {
        tempData = props.latestNews.find((element: any) => {
          return element.id === id;
        });
      }

      if (props.news && props.news.length > 0) {
        tempData = props.news.find((element: any) => {
          return element.id === id;
        });
      }

      if (props.events && props.events.length > 0) {
        tempData = props.events.find((element: any) => {
          return element.id === id;
        });
      }

      if (props.allMediaCenter && props.allMediaCenter.length > 0) {
        tempData = props.allMediaCenter.find((element: any) => {
          return element.id === id;
        });
      }

      if (props.mediaCenter && props.mediaCenter.length > 0) {
        tempData = props.mediaCenter.find((element: any) => {
          return element.id === id;
        });
      }
    }

    // console.log(tempData)
    if (tempData) {
      setData(tempData);
    }
    else {
      api.getOneArticle('id=' + id).then((result: any) => {
        setData(result.data);
      })
    }
  }, [])

  return (
    <>
      <div className="details-page">
        {/* <div className="banner">
          <div className="overlay">
            <h3>تفاصيل المحتوي</h3>
            <h5>{content?.title}</h5>
          </div>
        </div> */}
        <div className={!isPSData ? "page-with-sidebar" : "page-president"}>
        {!isPSData ? (
                <>
          <div className="sidebar">
            <SideBar />
          </div>
</>
        ):''}
          <div className="content">
            <div className="main-img">
              <img src={content?.image} alt="datails" />
            </div>
            <div className="meta-data">
              {isPSData ? (
                <>
                  <span className="sp-data" >
                    رئيس الاتحاد
                  </span>
                </>
              ) :
                (
                  <>
                    <span>
                      <img src="/content/images/icons/calendar-green.svg" alt="calendar" />
                      {
                        content?.publishDate ? (
                          <>
                            {format(Date.parse(content?.publishDate), "dd MMMM, yyyy", { locale: arSA })}
                          </>
                        ) : ''
                      }

                      {/* {new Date(content?.publishDate)} */}
                      {/* {format(new Date(Date.parse(content?.publishDate)), "yyyy-MM-dd")} */}
                    </span>
                    <span>
                      <img src="/content/images/icons/location-green.svg" alt="calendar" />
                      الرياض
                    </span>
                  </>
                )}

            </div>
            <div className="title">
              <h3>
                {content?.title}
              </h3>
            </div>
            <div className="description" dangerouslySetInnerHTML={{ __html: content?.bodyDecs.replace(/font-family:[^;']*(;)?/g, "") }}>

            </div>
            {
              content?.newsLink ? (
                <>
                  <div className="post-author">
                    <a href={content?.newsLink} target="_blank" >
                      مصدر الخبر
                    </a>
                  </div>
                </>
              ) : ''
            }
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    slider: state.home.slider,
    latestNews: state.home.latestNews,
    news: state.home.news,
    events: state.home.events,
    allMediaCenter: state.home?.allMediaCenter?.newspaper,
    mediaCenter: state.home?.mediaCenter?.newspaper
    // mediaCenter: state.home.mediaCenter
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPage);
