import { actionType } from "../actionTypes";
import * as api from "../../api/susfApi";


export function getShared(data) {
  return { type: actionType.GET_SHARED, data };
}

export function getSharedApi() {
  return function (dispatch) {
    return api.getShared().then((result) => {
      dispatch(getShared(result?.data));
    });
  };
}


export function getHome(data) {
  return { type: actionType.GET_HOME, data };
}

export function getHomeApi() {
  return function (dispatch) {
    return api.getHome().then((result) => {
      dispatch(getHome(result?.data));
      dispatch(getSlider(result?.data?.slider));
    });
  };
}

export function getSlider(data) {
  return { type: actionType.GET_SLIDER, data };
}

//#region  News & events
//Latest news
export function getLatestNewsApi(param) {
  return function (dispatch) {
    return api.getLatestNews(param).then((result) => {
      dispatch(getLatestNews(result?.data?.latestNews));
    });
  };
}
export function getLatestNews(data) {
  return { type: actionType.GET_LATEST_NEWS, data };
}

export function getLatestEventsApi(param) {
  return function (dispatch) {
    return api.getLatestNews(param).then((result) => {
      dispatch(getLatestEvents(result?.data?.latestNews));
    });
  };
}
export function getLatestEvents(data) {
  return { type: actionType.GET_LATEST_Events, data };
}


// All news
export function getNewsApi(param) {
  return function (dispatch) {
    return api.getLatestNews(param).then((result) => {
      dispatch(getNews(result?.data?.latestNews));
    });
  };
}
export function getNews(data) {
  return { type: actionType.GET_NEWS, data };
}

//one news
export function getOneArticleApi(param) {
  return function (dispatch) {
    return api.getOneArticle(param).then((result) => {
      dispatch(getOneArticle(result?.data));
    });
  };
}
export function getOneArticle(data) {
  return { type: actionType.GET_ONE_ARTICLE, data };
}


// All news
export function getEventsApi(param) {
  return function (dispatch) {
    return api.getLatestNews(param).then((result) => {
      dispatch(getEvents(result?.data?.latestNews));
    });
  };
}
export function getEvents(data) {
  return { type: actionType.GET_EVENTS, data };
}
//#endregion end news

//Media center
export function getMediaCenterApi(param) {
  return function (dispatch) {
    return api.getMediaCenter(param).then((result) => {
      dispatch(getMediaCenter(result?.data));
    });
  };
}
export function getMediaCenter(data) {
  return { type: actionType.GET_MEDIA_CENTER, data };
}
export function getAllMediaCenterApi(param) {
  return function (dispatch) {
    return api.getMediaCenter(param).then((result) => {
      dispatch(getAllMediaCenter(result?.data));
    });
  };
}
export function getAllMediaCenter(data) {
  return { type: actionType.GET_ALL_MEDIA_CENTER, data };
}


// Links forms
export function getLinksFormsApi() {
  return function (dispatch) {
    return api.getLinksForms().then((result) => {
      dispatch(getLinksForms(result?.data));
    });
  };
}
export function getLinksForms(data) {
  return { type: actionType.GET_LINKS_FORMS, data };
}

// Publications
export function getPublicationApi(param) {
  return function (dispatch) {
    return api.getPublication(param).then((result) => {
      dispatch(getPublication(result?.data));
    });
  };
}
export function getPublication(data) {
  return { type: actionType.GET_PUBLICATION, data };
}

export function getAllPublicationApi() {
  return function (dispatch) {
    return api.getAllPublication().then((result) => {
      dispatch(getAllPublication(result?.data));
    });
  };
}
export function getAllPublication(data) {
  return { type: actionType.GET_ALL_PUBLICATION, data };
}



// Ads

export function getAllAdsApi() {
  return function (dispatch) {
    return api.getAds().then((result) => {
      dispatch(getAllAds(result?.data));
    });
  };
}
export function getAllAds(data) {
  return { type: actionType.GET_ALL_ADS, data };
}

export function getAdsApi() {
  return function (dispatch) {
    return api.getAds().then((result) => {
      dispatch(getAds(result?.data));
    });
  };
}
export function getAds(data) {
  return { type: actionType.GET_ADS, data };
}

// All Law
export function getLawApi() {
  return function (dispatch) {
    return api.getLaw().then((result) => {
      dispatch(getLaw(result?.data));
    });
  };
}
export function getLaw(data) {
  return { type: actionType.GET_LAW, data };
}

// Competions
export function getCompetionsApi() {
  return function (dispatch) {
    return api.getCompetions().then((result) => {
      dispatch(getCompetions(result?.data));
    });
  };
}
export function getCompetions(data) {
  return { type: actionType.GET_COMPETION, data };
}


export function getLatestMatchesResultApi() {
  return function (dispatch) {
    return api.getLatestMatchesResult().then((result) => {
      dispatch(getLatestMatchesResult(result?.data));
    });
  };
}
export function getLatestMatchesResult(data) {
  return { type: actionType.GET_LATEST_MATCHES_RESULT, data };
}

export function getHonerApi() {
  return function (dispatch) {
    return api.getHoner().then((result) => {
      dispatch(getHoner(result?.data));
    });
  };
}
export function getHoner(data) {
  return { type: actionType.GET_HONER, data };
}