import React, { useEffect, useState } from "react";
import * as action from "../../../redux/actions/homeActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import { arSA } from 'date-fns/locale';
import { Link } from "react-router-dom";

function News(props: any) {
  const [newsData, setNewsData] = useState<any>([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.news || props.news.length <= 0) {
      props.getNews("moduleNumber=1.3");
    }
  }, [])

  useEffect(() => {
    setNewsData(props.news ? props.news : []);
  }, [props.news])

  return (
    <>
      <div className="news">
        <div className="banner">
          <div className="overlay">
            <h3>الأخبار</h3>
            <h5>الأخبار</h5>
          </div>
        </div>
        <div className="news-grid">
          <div className="container">
            <div className="row">

              {newsData ? (
                <>
                  {newsData.map((elem: any) => (
                    <>
                      <div className="col-md-6 col-lg-4" key={elem.id} >
                        <div className="news-box">
                          <Link to={`/details/${elem.id}`} >
                            <img className="main-img" src={elem.image} alt="news" />
                          </Link>
                          <div className="meta-data">
                            <span>
                              <img src="./content/images/icons/calendar-green.svg" alt="calendar" />
                              {
                                elem?.publishDate ? (
                                  <>
                                    {format(Date.parse(elem?.publishDate), "dd MMMM", { locale: arSA })}
                                  </>
                                ) : ''
                              }
                            </span>
                            <span>
                              <img src="./content/images/icons/location-green.svg" alt="calendar" />
                              الرياض
                            </span>
                          </div>
                          <h3 className="title climp num2">
                            {elem.title}
                          </h3>
                          <p className="description climp num3" dangerouslySetInnerHTML={{ __html: elem.bodyDecs.replace(/<img .*?>/g, "") }}>

                          </p>
                          <div className="readMore">
                            <Link to={`/details/${elem.id}`} >
                              <img src="./content/images/icons/left-icon-white.svg" alt="left-arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : ''
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



function mapStateToProps(state: any) {
  return {
    news: state.home.news,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getNews: bindActionCreators(action.getNewsApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(News);