import React, { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as action from "../../../../redux/actions/homeActions";
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle
// } from "reactstrap";

import { NavLink, useLocation } from "react-router-dom";
const Header = (props: any) => {
  const location = useLocation();

  // Topbar Dropdown
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen((prevState) => !prevState);

  // Header Navbar Collapse
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => setIsOpen(!isOpen);

  // About union Dropdown
  const [aboutUnionIsOpen, setAboutUnionIsOpen] = useState(false);
  const toggleAboutUnionDropdown = () => {
   // setMediaIsOpen(false);
   // setAboutUnionIsOpen(!aboutUnionIsOpen);
  }

  // Media Center Dropdown
  const [mediaIsOpen, setMediaIsOpen] = useState(false);
  const toggleMediaCenterDropdown = () => {
    //setAboutUnionIsOpen(false);
   // setMediaIsOpen(!mediaIsOpen);
  }

  let pathSplit;
  if (location.pathname === "/") {
    pathSplit = true;
  } else {
    pathSplit = false;
  }


  // Create Sticky Header
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef: any = useRef(null);

  // handle scroll event
  const handleScroll = (elTopOffset: any, elHeight: any) => {
    if (window.pageYOffset > (elTopOffset + elHeight + 70)) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  // add/remove scroll event listener
  useEffect(() => {
    // Bind data
    // debugger;
    if (!props.sharedData) {
      props.getShared();
    }

    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height)
    }

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  const sharedData = props.sharedData?.sharedData;

  return (
    <div className={`main-header ${pathSplit ? "homeHeader" : ""}`}>
      <div className={sticky.isSticky ? ' fixed' : ''} ref={headerRef}>
        <div className="topbar">
          <div className="left">
            <ul className="list-unstyled">
              <li>
                <a href={sharedData?.snapchat} className="snapchat" target="_blank"></a>
              </li>
              <li>
                <a href={sharedData?.twitter} className="twitter" target="_blank"></a>
              </li>
              <li>
                <a href={sharedData?.instagram} className="instagram" target="_blank"></a>
              </li>
              <li>
                <a href={sharedData?.youtube} className="youtube" target="_blank"></a>
              </li>
              {/* <li>
              <a href={sharedData?.facebook} className="facebook" target="_blank"></a>
            </li> */}
            </ul>
          </div>
          <div className="right">
            <ul className="list-unstyled">
              {/* <li>
              <a href="javascript:void(0)" className="search"></a>
            </li> */}
              {/* <li>
                <NavLink to="/faq">الاسئلة المتداولة</NavLink>
              </li> */}
              <li>
                <NavLink to="/termsandconditions">الشروط والأحكام</NavLink>
              </li>
              <li>
                <NavLink to="/support">الدعم الفني</NavLink>
              </li>
              {/* <li>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>English</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>عربي</DropdownItem>
                  <DropdownItem>English</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li> */}
            </ul>
          </div>
        </div>

        <div className="header-items">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <NavLink to="/" className="navbar-brand">
                <img alt="" src="/content/images/icons/logo1.svg" />
              </NavLink>
              <button aria-label="Toggle navigation" className={`navbar-toggle ${!isOpen ? 'collapsed' : ''}`} type="button" onClick={toggleNavbar}>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink to="/" className="nav-link" exact={true}>الرئيسية</NavLink>
                  </li>
                  <li className="dropdown nav-item">
                    <a aria-expanded={aboutUnionIsOpen} aria-haspopup="true"
                      className="dropdown-toggle nav-link"
                      onClick={toggleAboutUnionDropdown}>الإتحاد الرياضي</a>
                    <div aria-hidden="true" className={`dropdown-menu dropdown-menu-right ${aboutUnionIsOpen ? 'show' : ''}`} role="menu" >
                      <NavLink to="/about-union" className="dropdown-item" onClick={toggleAboutUnionDropdown}>عن الإتحاد</NavLink>
                      <NavLink to="/adminStructure" className="dropdown-item" onClick={toggleAboutUnionDropdown}>الهيكل الإداري</NavLink>
                      {/* <NavLink to="/directorsBoard" className="dropdown-item" onClick={toggleAboutUnionDropdown}>مجلس الإدارة</NavLink> */}
                      <NavLink to="/laws" className="dropdown-item" onClick={toggleAboutUnionDropdown}>اللوائح والقوانين</NavLink>
                    </div>
                  </li>
                  <li className="dropdown nav-item">
                    <a aria-expanded={mediaIsOpen} aria-haspopup="true"
                      className="dropdown-toggle nav-link"
                      onClick={toggleMediaCenterDropdown}>المركز الإعلامي</a>
                    <div aria-hidden="true" className={`dropdown-menu dropdown-menu-right ${mediaIsOpen ? 'show' : ''}`} role="menu" >
                      <NavLink to="/news" className="dropdown-item" onClick={toggleMediaCenterDropdown}>الأخبار</NavLink>
                      <NavLink to="/events" className="dropdown-item" onClick={toggleMediaCenterDropdown}>الفعاليات</NavLink>
                      <NavLink to="/videos" className="dropdown-item" onClick={toggleMediaCenterDropdown}>الوسائط</NavLink>
                      <NavLink to="/photos" className="dropdown-item" onClick={toggleMediaCenterDropdown}>الصور</NavLink>
                      <NavLink to="/advertisements" className="dropdown-item" onClick={toggleMediaCenterDropdown}>الإعلانات</NavLink>
                      <NavLink to="/media-file" className="dropdown-item" onClick={toggleMediaCenterDropdown}>الملف الإعلامي</NavLink>
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/competitions" className="nav-link">المسابقات</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/results" className="nav-link">النتائج</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/prints" className="nav-link">المطبوعات</NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    sharedData: state.home.sharedData
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getShared: bindActionCreators(action.getSharedApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);