import { handleResponse, handleError } from "./apiUtils";

export function ApiHeader(isFormData = false) {
  let token = localStorage.getItem("token");
  if (token) {
    if (isFormData)
      return {
        "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Headers": "X-Requested-With",
        Authorization: `Bearer ${token}`,
      };
    else
      return {
        "content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Headers": "X-Requested-With",
        Authorization: `Bearer ${token}`,
      };
  } else {
    if (isFormData)
      return {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        // "Access-Control-Allow-Headers": "X-Requested-With",
      };
    else
      return {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        // "Access-Control-Allow-Headers": "X-Requested-With",
      };
  }
}

export function GetApi(endPoint, queryParam) {
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://m.susfweb.com/' : 'http://localhost:3120/';

  // const baseUrl = "http://localhost:3110/";
  // const baseUrl = "https://m.susfweb.com/";

  let url = baseUrl + endPoint;
  if (queryParam)
    url = url + "?" + queryParam;

  const _header = ApiHeader();
  return fetch(url, {
    method: "GET",
    headers: _header,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function PostApi(endPoint, body) {
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://m.susfweb.com/' : 'http://localhost:3110/';

  let url = baseUrl + endPoint;
 
  const _header = ApiHeader();
  return fetch(url, {
    method: "POST",
    headers: _header,
    body: JSON.stringify(body)
  })
    .then(handleResponse)
    .catch(handleError);
}