import { actionType } from "../actionTypes";
import * as api from "../../api/susfApi";


export function getSessons(data) {
    return { type: actionType.GET_SESSONS, data };
  }
  
  export function getSessonsApi() {
    return function (dispatch) {
      return api.getSessons().then((result) => {
        dispatch(getSessons(result.data));
      });
    };
  }