import React, { useEffect } from "react";

function AdminStructure() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <div className="admin-structure">
        <div className="banner">
          <div className="overlay">
            <h3>الهيكل الإداري</h3>
            <h5>الهيكل الإداري</h5>
          </div>
        </div>
        <div className="data">
          <h3>يتكون الهيكل الإداري للاتحاد الرياضي للجامعات السعودية من</h3>
          <ul className="list-unstyled">
            <li>رئيس مجلس إدارة الاتحاد ( وزير التعليم ).</li>
            <li>مجلس إدارة الاتحاد .</li>
            <li>رئيس الاتحاد .</li>
            <li>الجمعية العمومية .</li>
            <li>الهيئة الاستشارية .</li>
            <li>أمين عام الاتحاد .</li>
          </ul>

          <h5>
            ولتسيير أمور الاتحاد يتم تكوين لجان متخصصة دائمة تسهل مهام الاتحاد
            وتساعد على اتخاذ القرارات,
          </h5>
          <h5>ويوضح ذلك الشكل التالي :</h5>
        </div>

        <div className="org-chart">
          <div className="top-part">
            <div className="levelup">
              <div className="box">
                مجلس الإدارة
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
            </div>
            <div className="levelmiddle">
              <div className="box">
                الهئية الإستشارية
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
              <div className="box">
                الجمعية العمومية
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
            </div>
            <div className="leveldown">
              <div className="box">
                رئيس الإتحاد
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
              {/* <div className="box">
              الأمين العام
                <span className="dashes"></span>
                <span className="separator"></span>
              </div> */}
            </div>
             <div className="leveldown2">
              <div className="box">
                الأمين العام
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
            </div> 
          </div>
          
         
          {/* <div className="leveldown">
              <div className="box">
              الأمين العام
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
            </div> */}
          

          <div className="low-part">
            <div className="levelup">
              <div className="box">
                اللجنة الإدارية المالية
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
              <div className="box">
                اللجنة الفنية
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
              <div className="box">
                لجنة المسابقات
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
              <div className="box">
                لجنة الاعلام العلاقات
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
            </div>
            <div className="leveldown">
              <div className="box">
                اللجنة القانونية
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
              <div className="box">
                اللجنة التنظيمية
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
              <div className="box">
                اللجنة الطبية
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
              <div className="box">
                لجنة الدراسات التطوير
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminStructure;
