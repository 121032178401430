import React, { useEffect, useState, useRef } from "react";
import { postTicket } from "../../../api/susfApi";
import ReCAPTCHA from "react-google-recaptcha";
import { Toast } from 'primereact/toast';
import { useParams } from "react-router-dom";

function Support() {
    const { id } = useParams();

    const toast = useRef(null);
    const [loding, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [about, setAbout] = useState(id);
    const [email, setEmail] = useState("");
    const [nId, setNId] = useState("");
    const [mobile, setMobile] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        debugger;
        if (id) {
            setAbout("طلب خدمة إعلان")
        }
    }, [id])


    const send = () => {
        setLoading(true);
        // Validation
        if (!name || !about || !email || !nId || !mobile) {
            toast.current.show({ severity: 'warn', summary: 'خطأ', detail: 'جميع الحقول مطلوبة', life: 5000 });
            setLoading(false);
            return;
        }
        recaptchaRef.current.execute()
            .then(ele => {
                if (ele != '') {
                    postTicket({ name: name, about: about, email: email, nationalId: nId, mobile: mobile, description: description }).then(res => {
                        if (res && res.successful) {
                            toast.current.show({ severity: 'success', summary: 'تم إرسال الطلب', detail: 'سنقوم بالتواصل معكم فى أقرب وقت', life: 5000 });
                            setName("");
                            setAbout("");
                            setEmail("");
                            setNId("");
                            setMobile("");
                            setDescription("");
                        }
                        setLoading(false);
                    })
                }
            });


    }
    const recaptchaRef = React.useRef();
    return (
        <>
            <Toast ref={toast} />
            <div className="technical-support">
                <div className="banner">
                    <div className="overlay">
                        <h3>الدعم الفني</h3>
                        <h5>الدعم الفني</h5>
                    </div>
                </div>
                <div className="content">
                    <div className="form">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LcFLtgcAAAAAGV-jIFXB39ivPwFMzMJdB2mtGoR"
                        />
                        <img src="../content/images/icons/communicate.svg" alt="faq" />
                        <h3>
                            تفاصيل الطلب
                        </h3>
                        <div className="fields">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>معلومات الطلب</label>
                                        <input value={about} onChange={(e) => setAbout(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label>الإسم الرباعي</label>
                                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label>البريد الإلكتروني</label>
                                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label>الهوية الوطنية</label>
                                        <input type="text" value={nId} onChange={(e) => setNId(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label>رقم الجوال</label>
                                        <input type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>الوصف</label>
                                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" ></textarea>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <button disabled={loding} className="btn" onClick={() => send()}>إرسال الطلب</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Support;
