import React, { useEffect, useState } from "react";
import SideBar from "../../shared/UI-components/sidebar/sidebar";
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import * as action from "../../../redux/actions/homeActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';

function Photos(props) {
  const [imagesData, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.allMediaCenter || props.allMediaCenter.length <= 0) {
      props.getAllMediaCenter();
    }
  }, [])

  useEffect(() => {
    setData(props.allMediaCenter ? props.allMediaCenter : []);
  }, [props.allMediaCenter])

  return (
  

    <>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.js">
    <div
        style={{
            height: '750px',
            width: '900px',
            marginLeft: 'auto',
            marginRight: 'auto',
        }}
    >
        <Viewer
            fileUrl={`${process.env.PUBLIC_URL}/pdf-open-parameters.pdf`}
        />
    </div>
</Worker> */}

      <div className="photos-page">
        <div className="banner">
          <div className="overlay">
            <h3>الصور</h3>
            <h5>الصور</h5>
          </div>
        </div>
        <div className="page-with-sidebar">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="content">
            <div className="grid">
              {imagesData && imagesData.length > 0 ? (
                <>
                  <Gallery>
                    {imagesData.map(photo => (
                      <Item
                        original={photo.image}
                        thumbnail={photo.image}
                        width="800"
                        height="600"
                      >
                        {({ ref, open }) => (
                          <img ref={ref} onClick={open} src={photo.image} />
                        )}
                      </Item>
                    ))}
                  </Gallery>
                </>
              ) : ''}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


function mapStateToProps(state) {
  return {
    allMediaCenter: state.home?.allMediaCenter?.images
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllMediaCenter: bindActionCreators(action.getAllMediaCenterApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Photos);