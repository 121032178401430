import React, { useEffect, useState } from "react";
import SideBar from "../../shared/UI-components/sidebar/sidebar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from "../../../redux/actions/homeActions";
import DocViewer, { BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer } from "react-doc-viewer";
import * as api from '../../../api/susfApi';
import { Dialog } from 'primereact/dialog';

function Laws(props: any) {
  const [lawData, setLawData] = useState<any>([]);
  const [docs, setDocViewer] = useState<any>([]);
  const [displayResponsive, setDisplayResponsive] = useState(false);


  const onHide = () => {
    setDisplayResponsive(false);
  }


  useEffect(() => {
    window.scrollTo(0,0);
    if (!props.law || props.law.length <= 0) {
      props.getLaw();
    }
  }, [])

  useEffect(() => {
    setLawData(props.law ? props.law : []);
  }, [props.law])

  const viewDoc = (attachKey: string, attachName: string) => {
    api.getFile(attachKey, attachName, "publication").then((response) => {
      // setDocViewer(response.data?.url);//[{ uri: response.data?.url }]);
      // setDisplayResponsive(true);
      window.open(response.data?.url,"_self");
    });
  }

  return (
    <>

      <Dialog header="مستعرض الملفات" visible={displayResponsive}  draggable={false} maximized={true} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} style={{ width: '75vw' }} >
      <iframe src={docs} className="iframe"></iframe>

        {/* <DocViewer config={{ header: { disableHeader: true, disableFileName: true, retainURLParams: false } }}
        documents={docs} pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer]} style={{ width: '70vw', height: '60vh' }} /> */}
      </Dialog>


      <div className="laws">
        <div className="banner">
          <div className="overlay">
            <h3>اللوائح والقوانين</h3>
            <h5>اللوائح والقوانين</h5>
          </div>
        </div>
        <div className="page-with-sidebar">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="content">
            <div className="container">
              <div className="row">

                {lawData ? (
                  <>
                    {lawData.map((elem: any) => (
                      <>
                        <div className="col-sm-12 col-md-12 col-lg-6" key={elem.name}>
                          <div className="laws-box">
                            <div className="info">
                              <h3>
                                {elem.name}
                              </h3>
                              {/* <h4>
                                {elem.description}
                              </h4> */}
                              <button className="btn" onClick={() => viewDoc(elem.attachKey, elem.attachName)}>مشاهدة</button>
                            </div>
                            <div className="img">
                              <img src={elem.image} alt="laws" />
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : ''
                }

                {/* 
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="laws-box">
                    <div className="info">
                      <h3>
                        دليل المسابقات
                        للطالبات
                      </h3>
                      <button className="btn">مشاهدة</button>
                    </div>
                    <div className="img">
                      <img src="./content/images/laws.png" alt="laws" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="laws-box">
                    <div className="info">
                      <h3>
                        دليل المسابقات
                        للطالبات
                      </h3>
                      <button className="btn">مشاهدة</button>
                    </div>
                    <div className="img">
                      <img src="./content/images/laws.png" alt="laws" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="laws-box">
                    <div className="info">
                      <h3>
                        دليل المسابقات
                        للطالبات
                      </h3>
                      <button className="btn">مشاهدة</button>
                    </div>
                    <div className="img">
                      <img src="./content/images/laws.png" alt="laws" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="laws-box">
                    <div className="info">
                      <h3>
                        دليل المسابقات
                        للطالبات
                      </h3>
                      <button className="btn">مشاهدة</button>
                    </div>
                    <div className="img">
                      <img src="./content/images/laws.png" alt="laws" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="laws-box">
                    <div className="info">
                      <h3>
                        دليل المسابقات
                        للطالبات
                      </h3>
                      <button className="btn">مشاهدة</button>
                    </div>
                    <div className="img">
                      <img src="./content/images/laws.png" alt="laws" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="laws-box">
                    <div className="info">
                      <h3>
                        دليل المسابقات
                        للطالبات
                      </h3>
                      <button className="btn">مشاهدة</button>
                    </div>
                    <div className="img">
                      <img src="./content/images/laws.png" alt="laws" />
                    </div>
                  </div>
                </div>
               */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



function mapStateToProps(state: any) {
  return {
    law: state.home.law,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getLaw: bindActionCreators(action.getLawApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Laws);