export const actionType = {
  GET_SHARED: "GET_SHARED",
  GET_SLIDER: "GET_SLIDER",
  GET_LATEST_NEWS: "GET_LATEST_NEWS",
  GET_LATEST_Events:"GET_LATEST_Events",
  GET_NEWS: "GET_NEWS",
  GET_EVENTS: "GET_EVENTS",
  GET_ONE_ARTICLE: "GET_ONE_ARTICLE",
  GET_HOME: "GET_HOME",
  GET_MEDIA_CENTER: "GET_MEDIA_CENTER",
  GET_ALL_MEDIA_CENTER: "GET_ALL_MEDIA_CENTER",
  GET_LINKS_FORMS: "GET_LINKS_FORMS",
  GET_PUBLICATION: "GET_PUBLICATION",
  GET_ALL_PUBLICATION:"GET_ALL_PUBLICATION",
  GET_ADS: "GET_ADS",
  GET_ALL_ADS: "GET_ALL_ADS",
  GET_LAW: "GET_LAW",
  GET_COMPETION:"GET_COMPETION",

  GET_LATEST_MATCHES_RESULT:"GET_LATEST_MATCHES_RESULT",
  GET_HONER:"GET_HONER",
  GET_SESSONS: "GET_SESSONS"
};
