import React, { useEffect } from "react";
import { Carousel } from "primereact/carousel";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from "../../../../redux/actions/homeActions";
import { Link } from "react-router-dom";
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

function SideBar(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!props.latestNews || props.latestNews.length <= 0) {
            props.getLatestNews("limit=3&moduleNumber=1.2");
            props.getAds();
        }
    }, [])

    const adsImageData = props.ads && props.ads.length > 0 ?
        (props.ads[Math.floor(Math.random() * props.ads.length)]) : {};

    // Adds Slider Settings
    const news =
        props.latestNews && props.latestNews.length > 0 ? (
            props.latestNews
                .map(x => {
                    return {
                        id: x.id,
                        title: x.title,
                        image: x.image
                    };
                })) : [];

    const newsResponsiveOptions = [
        {
            breakpoint: "1200px",
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: "1024px",
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: "600px",
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: "480px",
            numVisible: 1,
            numScroll: 1,
        },
    ];

    const newsTemplate = (news) => {
        return (
            <div className="news-item">
                <div className="news-item-content">
                    <div className="img">
                        <img
                            src={news.image}
                            alt="news"
                            className="new-image"
                        />
                    </div>
                    <Link to={`/details/${news.id}`} >
                        <h3 >
                            <ResponsiveEllipsis
                                unsafeHTML={news.title}
                                maxLine='3'
                                ellipsis='...'
                            />
                            {/* className="climp num3" {news.title} */}
                        </h3>
                    </Link>
                </div>
            </div>
        );
    };
    // End Adds Slider Settings
    return (
        <>
            <div className="latestnews">
                <h3 className="title">احدث الاخبار</h3>
                <Carousel
                    value={news}
                    itemTemplate={newsTemplate}
                    numVisible={1}
                    numScroll={1}
                    responsiveOptions={newsResponsiveOptions}
                    circular={true}
                ></Carousel>
            </div>
            <div className="latest-matches">
                <div className="title">
                    <h3>
                        <img src="/content/images/icons/live-streaming.svg" alt="" />
                        احدث المباريات
                    </h3>
                    <h5>
                        &nbsp;
                        {/* يمكنك سرد الأحداث الخاصة بك مع نظام إدارة */}
                    </h5>
                </div>
                <div className="box-body">
                    <div className="emptyData">
                        <img src="/content/images/nodata.png" alt="no data" />
                        <span>لا يوجد</span>
                    </div>
                    {/* <div className="score">
                        <div className="left">
                            <img src="/content/images/KFU_logo_white.png" alt="uni" />
                            <span>جامعة الملك فيصل</span>
                        </div>
                        <div className="middle">
                            <span>2 - 0</span>
                        </div>
                        <div className="right">
                            <img src="/content/images/SU-logo.png" alt="uni" />
                            <span>جامعة الامام سعود</span>
                        </div>
                    </div>
                    <div className="date-location">
                        <div className="date">
                            August 7, 2021
                            <img src="/content/images/icons/calendar-white.svg" alt="uni" />
                        </div>
                        <div className="location">
                            <img src="/content/images/icons/location-white.svg" alt="uni" />
                            جامعة الملك فيصل
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="adds">
                {
                    adsImageData && adsImageData.image ? (
                        // var rndm =Math.floor(Math.random() * props.ads.length);
                        <>
                            <a href={adsImageData.url} target="_blank">
                                <img src={adsImageData.image} alt="sidebar" />
                            </a>
                        </>
                    ) : ''
                }

            </div>
        </>
    );
}



function mapStateToProps(state) {
    return {
        latestNews: state.home.latestNews,
        ads: state.home.ads
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLatestNews: bindActionCreators(action.getLatestNewsApi, dispatch),
        getAds: bindActionCreators(action.getAdsApi, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);