import React, { useEffect, useState } from "react";
import SideBar from "../../shared/UI-components/sidebar/sidebar";
import * as action from "../../../redux/actions/homeActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function Advertisments(props: any) {
  const [adsData, setData] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.allAds || props.allAds.length <= 0) {
      props.getAllAds();
    }
  }, [])

  useEffect(() => {
    setData(props.allAds ? props.allAds : []);
  }, [props.allAds])

  return (
    <>
      <div className="advertisments">
        <div className="banner">
          <div className="overlay">
            <h3>الإعلانات</h3>
            <h5>الإعلانات</h5>
          </div>
        </div>
        <div className="page-with-sidebar">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="content">
            <div className="container">
              <div className="row">
                {adsData && adsData.length > 0 ? (
                  <>

                    {adsData.map((advertice: any) => (
                      <div className="col-sm-12 col-md-6 col-lg-4" key={advertice._id}>
                        <div className="advertice-box">
                          <a href={advertice.url} target="_blank">
                            <img src="./content/images/icons/ext-link.svg" alt="link" />
                          </a>
                          <img
                            src={advertice.image}
                            alt="main-img"
                            className="main-img"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : ''
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



function mapStateToProps(state: any) {
  return {
    allAds: state.home?.allads
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getAllAds: bindActionCreators(action.getAllAdsApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Advertisments);