import React, { useEffect } from "react";

function DirectorsBoard() {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  
  return (
    <>
      <div className="directors-board">
        <div className="banner">
          <div className="overlay">
            <h3>مجلس الإدارة</h3>
            <h5>مجلس الإدارة</h5>
          </div>
        </div>
        <div className="directors-list">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="box president">
                  <img
                    src="./content/images/icons/president-level.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً ونائب للرئيس</span>
                    <h4>د.محمد بن أحمد السديري</h4>
                    <p>نائب وزير التعليم للجامعات البحث الابتكار</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box president">
                  <img
                    src="./content/images/icons/president-level.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>رئيساً</span>
                    <h4>د.حمد بن محمد أل الشيخ</h4>
                    <p>وزير التعليم </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box president">
                  <img
                    src="./content/images/icons/president-level.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>صاحب السمو الملكي الأمير/ خالد بن الوليد بن طلال </h4>
                    <p>رئيس الاتحار السعودي للرياضة للجميع</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-male.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.بدران بن عبد الرحمن العمر </h4>
                    <p>رئيس جامعة الملك سعود</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-male.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.عبد الرحمن بن عبيد اليوبي</h4>
                    <p>رئيس جامعة الملك عبد العزيز</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-female.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.ايناس بنت سليمان العيسى</h4>
                    <p>رئيسة جامعة الأميرة نورة بنت عبد الرحمن</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-male.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.عبد الرحمن بن ناصر الخريف</h4>
                    <p>وكيل الوزارة للتعليم الجامعي</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-female.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.ريمة بنت صالح اليحيا</h4>
                    <p>وكيل الوزارة للتعليم الجامعي الاهلي</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-male.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.محمد بن أحمد السديري</h4>
                    <p>نائب وزير التعليم للجامعات البحث الابتكار</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-male.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.محمد بن أحمد السديري</h4>
                    <p>نائب وزير التعليم للجامعات البحث الابتكار</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-male.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.محمد بن أحمد السديري</h4>
                    <p>نائب وزير التعليم للجامعات البحث الابتكار</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box member">
                  <img
                    src="./content/images/icons/member-level-male.svg"
                    alt=""
                  />
                  <div className="info">
                    <span>عضواً</span>
                    <h4>د.محمد بن أحمد السديري</h4>
                    <p>نائب وزير التعليم للجامعات البحث الابتكار</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DirectorsBoard;
