import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import "./app.scss";
import Footer from "./shared/layout/footer/footer";
import Header from "./shared/layout/header/header";

import Homepage from "./web/homepage/homepage";
import AboutUnion from "./web/aboutunion/aboutunion";
import AdminStructure from "./web/adminStructure/adminStructure";
import DirectorsBoard from "./web/directorsBoard/directorsBoard";
import Laws from "./web/laws/laws";
import News from "./web/news/news";
import Events from "./web/events/events";
import DetailsPage from "./web/details-page/details-page";
// import TechnicalSupport from "./web/technical-support/technical-support";
import TermsandConditions from "./web/terms-conditions/terms-conditions";
import Faq from "./web/faq/faq";
import Competitions from "./web/competitions/competitions";
import Videos from "./web/videos/videos";
import Photos from "./web/photos/photos";

import "bootstrap/scss/bootstrap.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Advertisments from "./web/adverticements/advertisments";
import MediaFile from "./web/media-file/media-file";
import Prints from "./web/prints/prints";
import Results from "./web/results/results";
import Support from "./web/technical-support/support";
// import { Worker } from '@react-pdf-viewer/core';



function App() {
  return (
    
    <Router>
      <Header />
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.js"></Worker> */}

      <Switch>
      
        <Route path="/" exact component={Homepage} />
        <Route path="/about-union" component={AboutUnion} />
        <Route path="/adminStructure" component={AdminStructure} />
        <Route path="/directorsBoard" component={DirectorsBoard} />
        <Route path="/laws" component={Laws} />
        <Route path="/news" component={News} />
        <Route path="/events" component={Events} />
        <Route path="/details/:id" component={DetailsPage} />
        <Route path="/competitions/:id/:ses" component={Competitions} />
        <Route path="/competitions" component={Competitions} />
        <Route path="/results" component={Results} />
        <Route path="/videos" component={Videos} />
        <Route path="/photos" component={Photos} />
        <Route path="/advertisements" component={Advertisments} />
        <Route path="/media-file" component={MediaFile} />
        <Route path="/prints" component={Prints} />
        
        <Route path="/support/:id" component={Support} />
        <Route path="/support" component={Support} />
        <Route path="/termsandconditions" component={TermsandConditions} />
        <Route path="/faq" component={Faq} />
        
        {/* <Route path="*" component={Homepage} /> */}

      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
