import React, { useEffect } from "react";

function AboutUnion() {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  
  return (
    <>
      <div className="about-union">
        <div className="banner">
          <div className="overlay">
            <h3>عن الاتحاد</h3>
            <h5>عن الاتحاد</h5>
          </div>
        </div>
        <div className="grid-left-right">
          <div className="item first">
            <div className="left">
              <img
                src="./content/images/aboutunion/aboutunion1.png"
                alt="pic1"
              />
            </div>
            <div className="right">
              <div>
                <h3>الرؤية</h3>
                <p>
                  تحقيق التميز والريادة للرياضة في الجامعات السعودية للوصول إلى
                  مستويات عالمية متقدمة بما يتناسب مع قيم الجامعات ومبادئها
                  وأهدافها .
                </p>
              </div>
            </div>
          </div>
          <div className="item second">
            <div className="left">
              <div>
                <h3>الرسالة</h3>
                <p>
                  رعاية الحركة الرياضية الجامعية ودعمها وتطويرها ورفع مستواها من
                  خلال مجتمع رياضــــــــــــي يشجع على تطبيق الروح الرياضية
                  ويؤكـــــــــــــــد علــى تعزيز ممارســة النشاط الرياضي
                  ونشــــــــــره بين طلاب الجامعات وتحديد القواعد ورسم السياسات
                  المنظمــــــــة للأنشطة وتمثيل وتنسيق المواقف والآراء للجامعات
                  السعـــــــــودية في المشاركات الرياضية المحلية والإقليمية
                  والدولية.
                </p>
              </div>
            </div>
            <div className="right">
              <img
                src="./content/images/aboutunion/aboutunion2.png"
                alt="pic1"
              />
            </div>
          </div>
          <div className="item third">
            <div className="left">
              <img
                src="./content/images/aboutunion/aboutunion3.png"
                alt="pic1"
              />
            </div>
            <div className="right">
              <div>
                <h3>الاهداف</h3>
                {/* <p>
                  لتحقيق رؤية الاتحاد الرياضي للجامعات السعودية ورسالته سوف يتم
                  التركيز على الأهداف التالية :
                </p> */}
                <p>
                  - تنظيم أنشطة ولقاءات رياضية عالية الجودة لطلاب الجامعات من خلال
                  تبني إطار تنظيمي مميز والتعاون المشترك مع الاتحادات الرياضية
                  الوطنية.
                </p>
                <p>
                  - الإشراف على الأنشطة الرياضية بين المؤسسات الواقعة تحت إشراف
                  وزارة التعليم.
                </p>
                <p>
                  - رعاية الحركة الرياضية الجامعية السعودية ودعمها وتطويرها.
                </p>
                <p>
                  - تمثيل الجامعات السعودية فى المشاركات الرياضيةالمحلية والإقليميةوالدولية.
                </p>
                <p>
                  - تنسيق المواقف والآراء في المشاركات الرياضية الجامعية المحلية
                  والإقليمية والدولية.
                </p>
                <p>
                  - الارتقاء بالرياضة الجامعية من مجرد نشاط طلابي غير صفي إلى
                  برامج منهجيه تؤكد على أهمية المشاركة في الأنشطة الرياضية كأحد
                  عناصر النمو المتكامل لإعداد طلاب الجامعة للحياة المهنية.
                </p>
                <p>
                  - تطوير البرامج الرياضية التربوية من خلال تبني مجموعة من
                  المعايير الأخلاقية بما يتناسب مع القضايا التربوية الرياضية
                  المعاصرة.
                </p>
                <p>
                  - دعم الدراسات والبحوث العلمية التي تؤدي إلى التوافق والانسجام
                  لتحقيق التميز الأكاديمي والرياضي وبما يعزز التواصل بين
                  الجامعات.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUnion;
