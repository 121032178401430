export default {
  // mediaCenter: {
  //   images: [],
  //   vidios: [],
  //   newsPaper: []
  // },
  latestNews: [],
  slider: [],
  // home: {

  //   //     slider: [
  //   //       {
  //   //         image_name: "JS152978224.jpg",
  //   //         inner_image_name: "download.jpg",
  //   //         title_en: "Tomorrow.. the Universities Sports Federation League",
  //   //         title_ar: "غدًا.. دوري الاتحاد الرياضي للجامعات",
  //   //         content_en: `Under the patronage of the Minister of Education, Chairman of the Board of Directors of the Sports Federation of Saudi Universities, Dr. Hamad bin
  //   // Muhammad Al-Sheikh and in the presence of the President of Hail University, Dr. Khalil bin Ibrahim Al-Brahim, with the President of the Federation
  //   // The athlete of Saudi universities, Dr. Khaled bin Saleh Al-Muzaini, with the President of the Saudi Football Association
  //   // The foot, Mr. Yasser bin Hassan Al-Mashal, and a number of federal officials, hosts the King's City
  //   // Abdulaziz bin Mosaed Sports in Hail, the final match of the Sports Federation of Saudi Universities League
  //   // for the season)`,
  //   //         content_ar: `تحت رعاية وزير التعليم رئيس مجلس إدارة الاتحاد الرياضي للجامعات السعودية الدكتور حمد بن
  //   // محمد آل الشيخ وبحضور رئيس جامعة حائل الدكتور خليل بن إبراهيم البراهيم،مع رئيس الاتحاد
  //   // الرياضي للجامعات السعودية الدكتور خالد بن صالح المزيني مع ورئيس الاتحاد السعودي لكرة
  //   // القدم الأستاذ ياسر بن حسن المسحل وعدد من مسؤولي الاتحاديين ، تستضيف مدينة الملك
  //   // عبدالعزيز بن مساعد الرياضية بحائل المباراة النهائية لدوري الاتحاد الرياضي للجامعات السعودية
  //   // للموسم)`,
  //   //       },
  //   //       {
  //   //         image_name: "50072053_303.jpg",
  //   //         inner_image_name: "images.jpg",
  //   //         title_en: "Tomorrow.. the Universities Sports Federation League",
  //   //         title_ar: "غدًا.. دوري الاتحاد الرياضي للجامعات",
  //   //         content_en: `Under the patronage of the Minister of Education, Chairman of the Board of Directors of the Sports Federation of Saudi Universities, Dr. Hamad bin
  //   // Muhammad Al-Sheikh and in the presence of the President of Hail University, Dr. Khalil bin Ibrahim Al-Brahim, with the President of the Federation
  //   // The athlete of Saudi universities, Dr. Khaled bin Saleh Al-Muzaini, with the President of the Saudi Football Association
  //   // The foot, Mr. Yasser bin Hassan Al-Mashal, and a number of federal officials, hosts the King's City
  //   // Abdulaziz bin Mosaed Sports in Hail, the final match of the Sports Federation of Saudi Universities League
  //   // for the season)`,
  //   //         content_ar: `تحت رعاية وزير التعليم رئيس مجلس إدارة الاتحاد الرياضي للجامعات السعودية الدكتور حمد بن
  //   // محمد آل الشيخ وبحضور رئيس جامعة حائل الدكتور خليل بن إبراهيم البراهيم،مع رئيس الاتحاد
  //   // الرياضي للجامعات السعودية الدكتور خالد بن صالح المزيني مع ورئيس الاتحاد السعودي لكرة
  //   // القدم الأستاذ ياسر بن حسن المسحل وعدد من مسؤولي الاتحاديين ، تستضيف مدينة الملك
  //   // عبدالعزيز بن مساعد الرياضية بحائل المباراة النهائية لدوري الاتحاد الرياضي للجامعات السعودية
  //   // للموسم)`,
  //   //       },
  //   //       {
  //   //         image_name:
  //   //           "race-track-and-field-running-sport-sprint-olympics-false-start-run-runners.jpg",
  //   //         inner_image_name: "peke-athletic.jpg",
  //   //         title_en: "Tomorrow.. the Universities Sports Federation League",
  //   //         title_ar: "غدًا.. دوري الاتحاد الرياضي للجامعات",
  //   //         content_en: `Under the patronage of the Minister of Education, Chairman of the Board of Directors of the Sports Federation of Saudi Universities, Dr. Hamad bin
  //   // Muhammad Al-Sheikh and in the presence of the President of Hail University, Dr. Khalil bin Ibrahim Al-Brahim, with the President of the Federation
  //   // The athlete of Saudi universities, Dr. Khaled bin Saleh Al-Muzaini, with the President of the Saudi Football Association
  //   // The foot, Mr. Yasser bin Hassan Al-Mashal, and a number of federal officials, hosts the King's City
  //   // Abdulaziz bin Mosaed Sports in Hail, the final match of the Sports Federation of Saudi Universities League
  //   // for the season)`,
  //   //         content_ar: `تحت رعاية وزير التعليم رئيس مجلس إدارة الاتحاد الرياضي للجامعات السعودية الدكتور حمد بن
  //   // محمد آل الشيخ وبحضور رئيس جامعة حائل الدكتور خليل بن إبراهيم البراهيم،مع رئيس الاتحاد
  //   // الرياضي للجامعات السعودية الدكتور خالد بن صالح المزيني مع ورئيس الاتحاد السعودي لكرة
  //   // القدم الأستاذ ياسر بن حسن المسحل وعدد من مسؤولي الاتحاديين ، تستضيف مدينة الملك
  //   // عبدالعزيز بن مساعد الرياضية بحائل المباراة النهائية لدوري الاتحاد الرياضي للجامعات السعودية
  //   // للموسم)`,
  //   //       },
  //   //     ],
  //   //     newNews: [
  //   //       {
  //   //         id: 0,
  //   //         image_name: "swim.jpg",
  //   //         title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //         title_en:
  //   //           "Coronation of the winning universities in the first three places",
  //   //         content_ar:
  //   //           "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //         content_en:
  //   //           "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //         full_content_ar: "",
  //   //         full_content_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "swim.jpg",
  //   //         title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //         title_en:
  //   //           "Coronation of the winning universities in the first three places",
  //   //         content_ar:
  //   //           "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //         content_en:
  //   //           "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //         full_content_ar: "",
  //   //         full_content_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "Volleybal.jpg",
  //   //         title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //         title_en:
  //   //           "Coronation of the winning universities in the first three places",
  //   //         content_ar:
  //   //           "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //         content_en:
  //   //           "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //         full_content_ar: "",
  //   //         full_content_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //         title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //         title_en:
  //   //           "Coronation of the winning universities in the first three places",
  //   //         content_ar:
  //   //           "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //         content_en:
  //   //           "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //         full_content_ar: "",
  //   //         full_content_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "download2222.jpg",
  //   //         title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //         title_en:
  //   //           "Coronation of the winning universities in the first three places",
  //   //         content_ar:
  //   //           "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //         content_en:
  //   //           "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //         full_content_ar: "",
  //   //         full_content_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //         title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //         title_en:
  //   //           "Coronation of the winning universities in the first three places",
  //   //         content_ar:
  //   //           "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //         content_en:
  //   //           "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //         full_content_ar: "",
  //   //         full_content_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "Volleybal.jpg",
  //   //         title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //         title_en:
  //   //           "Coronation of the winning universities in the first three places",
  //   //         content_ar:
  //   //           "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //         content_en:
  //   //           "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //         full_content_ar: "",
  //   //         full_content_en: "",
  //   //       },
  //   //     ],
  //   //     mediaCenter: {
  //   //       photos: [
  //   //         {
  //   //           id: 0,
  //   //           image_name: "swim.jpg",
  //   //           title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //           title_en:
  //   //             "Coronation of the winning universities in the first three places",
  //   //           content_ar:
  //   //             "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //           content_en:
  //   //             "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //           full_content_ar: "",
  //   //           full_content_en: "",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "swim.jpg",
  //   //           title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //           title_en:
  //   //             "Coronation of the winning universities in the first three places",
  //   //           content_ar:
  //   //             "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //           content_en:
  //   //             "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //           full_content_ar: "",
  //   //           full_content_en: "",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "Volleybal.jpg",
  //   //           title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //           title_en:
  //   //             "Coronation of the winning universities in the first three places",
  //   //           content_ar:
  //   //             "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //           content_en:
  //   //             "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //           full_content_ar: "",
  //   //           full_content_en: "",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //           title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //           title_en:
  //   //             "Coronation of the winning universities in the first three places",
  //   //           content_ar:
  //   //             "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //           content_en:
  //   //             "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //           full_content_ar: "",
  //   //           full_content_en: "",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "download2222.jpg",
  //   //           title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //           title_en:
  //   //             "Coronation of the winning universities in the first three places",
  //   //           content_ar:
  //   //             "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //           content_en:
  //   //             "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //           full_content_ar: "",
  //   //           full_content_en: "",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //           title_ar: "تتويج الجامعات الفائزة في المراكز الثلاث الأولى",
  //   //           title_en:
  //   //             "Coronation of the winning universities in the first three places",
  //   //           content_ar:
  //   //             "حققت #جامعة_الفيصل المركز الثالث في بطولة الروكيت ليق ضمن منافسات بطولة الاتحاد الرياضي للجامعات السعودية للألعاب الإل",
  //   //           content_en:
  //   //             "Al-Faisal University achieved third place in the Rocket League Championship within the Saudi Universities Sports Federation Championship for the Athletics",
  //   //           full_content_ar: "",
  //   //           full_content_en: "",
  //   //         },
  //   //       ],
  //   //       vedios: [
  //   //         {
  //   //           id: 0,
  //   //           image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //           url: "https://www.youtube.com/",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //           url: "https://www.youtube.com/",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //           url: "https://www.youtube.com/",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //           url: "https://www.youtube.com/",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //           url: "https://www.youtube.com/",
  //   //         },
  //   //         {
  //   //           id: 0,
  //   //           image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //           url: "https://www.youtube.com/",
  //   //         },
  //   //       ],
  //   //     },
  //   //     events: [
  //   //       {
  //   //         id: 1,
  //   //         image_name:
  //   //           "race-track-and-field-running-sport-sprint-olympics-false-start-run-runners.jpg",
  //   //         title_ar:
  //   //           "جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات",
  //   //         title_en:
  //   //           "King Abdulaziz University crowned the Saudi Universities Sports League",
  //   //         day: "18",
  //   //         month: "jul",
  //   //         year: "2021",
  //   //         fromTime: "10:00",
  //   //         fromTimeNoon: "pm",
  //   //         toTime: "10:00",
  //   //         toTimeNoon: "pm",
  //   //         isTop: true,
  //   //       },
  //   //       {
  //   //         id: 2,
  //   //         image_name: "swim.jpg",
  //   //         title_ar:
  //   //           "جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات",
  //   //         title_en:
  //   //           "King Abdulaziz University crowned the Saudi Universities Sports League",
  //   //         day: "18",
  //   //         month: "jul",
  //   //         year: "2021",
  //   //         fromTime: "10:00",
  //   //         fromTimeNoon: "pm",
  //   //         toTime: "10:00",
  //   //         toTimeNoon: "pm",
  //   //         isTop: true,
  //   //       },
  //   //       {
  //   //         id: 3,
  //   //         image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //         title_ar:
  //   //           "جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات",
  //   //         title_en:
  //   //           "King Abdulaziz University crowned the Saudi Universities Sports League",
  //   //         day: "18",
  //   //         month: "jul",
  //   //         year: "2021",
  //   //         fromTime: "10:00",
  //   //         fromTimeNoon: "pm",
  //   //         toTime: "10:00",
  //   //         toTimeNoon: "pm",
  //   //         isTop: true,
  //   //       },
  //   //       {
  //   //         id: 4,
  //   //         image_name:
  //   //           "race-track-and-field-running-sport-sprint-olympics-false-start-run-runners.jpg",
  //   //         title_ar:
  //   //           "جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات",
  //   //         title_en:
  //   //           "King Abdulaziz University crowned the Saudi Universities Sports League",
  //   //         day: "18",
  //   //         month: "jul",
  //   //         year: "2021",
  //   //         fromTime: "10:00",
  //   //         fromTimeNoon: "pm",
  //   //         toTime: "10:00",
  //   //         toTimeNoon: "pm",
  //   //         isTop: false,
  //   //       },
  //   //       {
  //   //         id: 5,
  //   //         image_name: "swim.jpg",
  //   //         title_ar:
  //   //           "جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات",
  //   //         title_en:
  //   //           "King Abdulaziz University crowned the Saudi Universities Sports League",
  //   //         day: "18",
  //   //         month: "jul",
  //   //         year: "2021",
  //   //         fromTime: "10:00",
  //   //         fromTimeNoon: "pm",
  //   //         toTime: "10:00",
  //   //         toTimeNoon: "pm",
  //   //         isTop: false,
  //   //       },
  //   //       {
  //   //         id: 6,
  //   //         image_name: "qsphzh2ubizgbyaqpxuk.jpg",
  //   //         title_ar:
  //   //           "6 جامعة الملك عبدالعزيز تُتوج ببطولة دوري الجامعات السعودية للرياضات",
  //   //         title_en:
  //   //           "King Abdulaziz University crowned the Saudi Universities Sports League",
  //   //         day: "18",
  //   //         month: "jul",
  //   //         year: "2021",
  //   //         fromTime: "10:00",
  //   //         fromTimeNoon: "pm",
  //   //         toTime: "10:00",
  //   //         toTimeNoon: "pm",
  //   //         isTop: false,
  //   //       },
  //   //     ],
  //   //     competitions: [
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "تنس طاولة",
  //   //         title_en: "",
  //   //       },
  //   //       { id: 0, image_name: "sport_ava.png", title_ar: "كرة قدم", title_en: "" },
  //   //       { id: 0, image_name: "sport_ava.png", title_ar: "الشيش", title_en: "" },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "كرة الطائرة",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "تنس طاولة",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "تنس طاولة",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "تنس طاولة",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "تنس طاولة",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "تنس طاولة",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "تنس طاولة",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         id: 0,
  //   //         image_name: "sport_ava.png",
  //   //         title_ar: "تنس طاولة",
  //   //         title_en: "",
  //   //       },
  //   //     ],

  //   //     forms: [
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         title_ar: "يمكنك سرد الأحداث الخاصة بك مع نظام",
  //   //         title_en: "en",
  //   //       },
  //   //     ],
  //   //     adds: [
  //   //       { id: 1, image_name: "awords.jpg" },
  //   //       { id: 1, image_name: "awords.jpg" },
  //   //       { id: 1, image_name: "awords.jpg" },
  //   //       { id: 1, image_name: "awords.jpg" },
  //   //       { id: 1, image_name: "awords.jpg" },
  //   //     ],
  //   //     Publications: [
  //   //       { id: 1, image_name: "sport.jpg" },
  //   //       { id: 1, image_name: "sport.jpg" },
  //   //       { id: 1, image_name: "sport.jpg" },
  //   //       { id: 1, image_name: "sport.jpg" },
  //   //       { id: 1, image_name: "sport.jpg" },
  //   //       { id: 1, image_name: "sport.jpg" },
  //   //     ],
  //   //     totals: [
  //   //       {
  //   //         id: 1,
  //   //         image_name: "collage.svg",
  //   //         title_en: "",
  //   //         title_ar: "الجامعات",
  //   //         count: "200",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "soccer-player-m.svg",
  //   //         title_en: "",
  //   //         title_ar: "الطلاب",
  //   //         count: "5000",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "soccer-player-f.svg",
  //   //         title_en: "",
  //   //         title_ar: "الطالبات",
  //   //         count: "4000",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "weather-forecast.svg",
  //   //         title_en: "",
  //   //         title_ar: "المواسم",
  //   //         count: "16",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "winner.svg",
  //   //         title_en: "",
  //   //         title_ar: "المسابقات",
  //   //         count: "100",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "Contest.svg",
  //   //         title_en: "",
  //   //         title_ar: "المسابقات الدوليه",
  //   //         count: "40",
  //   //       },
  //   //     ],
  //   //     honorBoard: [
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         image_name: "player_profile.png",
  //   //         type_ar: "طالب",
  //   //         type_en: "",
  //   //         name_ar: "محمد حسن",
  //   //         name_en: "",
  //   //         universty_ar: "جامعة الملك سعود",
  //   //         universty_en: "",
  //   //       },
  //   //     ],
  //   //     schedule: [
  //   //       {
  //   //         id: 1,
  //   //         first_un_name_ar: "المسابقه العامه للجماعات",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "",
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",

  //   //         match_location: "جامعة الملك فيصل",
  //   //         first_result: "3",
  //   //         second_result: "2",
  //   //         competition_logo: "football-player.svg",
  //   //         from_date: "6 jul",
  //   //         from_time: "11:20",
  //   //         is_public: true,
  //   //         is_new_match: true,
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "",
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "unv-logo.png",

  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "",
  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "unv-logo.png",

  //   //         match_location: "جامعة الملك فيصل",
  //   //         first_result: "3",
  //   //         second_result: "2",
  //   //         competition_logo: "football-player.svg",
  //   //         from_date: "6 jul",
  //   //         from_time: "11:20",
  //   //         is_public: false,
  //   //         is_new_match: true,
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "",
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "unv-logo.png",

  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "",
  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "unv-logo.png",

  //   //         match_location: "جامعة الملك فيصل",
  //   //         first_result: "3",
  //   //         second_result: "2",
  //   //         competition_logo: "football-player.svg",
  //   //         from_date: "6 jul",
  //   //         from_time: "11:20",
  //   //         is_public: false,
  //   //         is_new_match: false,
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "",
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "unv-logo.png",

  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "",
  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "unv-logo.png",

  //   //         match_location: "جامعة الملك فيصل",
  //   //         first_result: "3",
  //   //         second_result: "2",
  //   //         competition_logo: "football-player.svg",
  //   //         from_date: "6 jul",
  //   //         from_time: "11:20",
  //   //         is_public: false,
  //   //         is_new_match: true,
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "",
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "unv-logo.png",

  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "",
  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "unv-logo.png",

  //   //         match_location: "جامعة الملك فيصل",
  //   //         first_result: "3",
  //   //         second_result: "2",
  //   //         competition_logo: "football-player.svg",
  //   //         from_date: "6 jul",
  //   //         from_time: "11:20",
  //   //         is_public: false,
  //   //         is_new_match: true,
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "",
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "unv-logo.png",

  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "",
  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "unv-logo.png",

  //   //         match_location: "جامعة الملك فيصل",
  //   //         first_result: "3",
  //   //         second_result: "2",
  //   //         competition_logo: "football-player.svg",
  //   //         from_date: "6 jul",
  //   //         from_time: "11:20",
  //   //         is_public: false,
  //   //         is_new_match: false,
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "",
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "unv-logo.png",

  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "",
  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "unv-logo.png",

  //   //         match_location: "جامعة الملك فيصل",
  //   //         first_result: "3",
  //   //         second_result: "2",
  //   //         competition_logo: "football-player.svg",
  //   //         from_date: "6 jul",
  //   //         from_time: "11:20",
  //   //         is_public: false,
  //   //         is_new_match: false,
  //   //       },
  //   //       {
  //   //         id: 1,
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "",
  //   //         first_un_name_ar: "جامعة الملك فيصل",
  //   //         first_un_name_en: "",
  //   //         first_un_logo: "unv-logo.png",

  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "",
  //   //         second_un_name_ar: "جامعة الملك فيصل",
  //   //         second_un_name_en: "",
  //   //         second_un_logo: "unv-logo.png",

  //   //         match_location: "جامعة الملك فيصل",
  //   //         first_result: "3",
  //   //         second_result: "2",
  //   //         competition_logo: "football-player.svg",
  //   //         from_date: "6 jul",
  //   //         from_time: "11:20",
  //   //         is_public: false,
  //   //         is_new_match: false,
  //   //       },
  //   //     ],
  //   //     news: [
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //       {
  //   //         title_ar:
  //   //           "- جامعة وكلية في انطلاق بطولة الجامعات السعودية للرياضات الإلكترونية",
  //   //         title_en: "",
  //   //       },
  //   //     ],
  // },
};
