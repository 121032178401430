import { actionType } from "../actionTypes";
import initialState from "./initialState";

export default function addsReducers(state = initialState, action) {
  switch (action.type) {
    case actionType.GET_SHARED:
      return { ...state, sharedData: action.data };
    case actionType.GET_HOME:
      return { ...state, home: action.data };
    case actionType.GET_SLIDER:
      return { ...state, slider: action.data };
    case actionType.GET_LATEST_NEWS:
      return { ...state, latestNews: action.data };
      case actionType.GET_LATEST_Events:
        return { ...state, latestEvents: action.data };
    case actionType.GET_NEWS:
      return { ...state, news: action.data };
    case actionType.GET_EVENTS:
      return { ...state, events: action.data };
    case actionType.GET_ONE_ARTICLE:
      return { ...state, onepage: action.data };
    case actionType.GET_MEDIA_CENTER:
      return { ...state, mediaCenter: action.data };
    case actionType.GET_ALL_MEDIA_CENTER:
      return { ...state, allMediaCenter: action.data };
    case actionType.GET_LINKS_FORMS:
      return { ...state, linksforms: action.data };
    case actionType.GET_PUBLICATION:
      return { ...state, publication: action.data };
    case actionType.GET_ALL_PUBLICATION:
      return { ...state, allPublication: action.data };
    case actionType.GET_ADS:
      return { ...state, ads: action.data };
    case actionType.GET_ALL_ADS:
      return { ...state, allads: action.data };
    case actionType.GET_LAW:
      return { ...state, law: action.data };
    case actionType.GET_COMPETION:
      return { ...state, competion: action.data };

    case actionType.GET_LATEST_MATCHES_RESULT:
      return { ...state, latestMatchesResult: action.data };

    case actionType.GET_HONER:
      return { ...state, honer: action.data };

    default:
      return state;
  }
}
