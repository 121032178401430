import React, { Suspense } from "react";
import ReactDOM from "react-dom";
//=============== Redux =================>
import configureStore from "./redux/configureStore";

import { Provider as ReduxProvider } from "react-redux";
//=============== End Redux =================>
import App from "./app/app";

const store = configureStore();
ReactDOM.render(
  <ReduxProvider store={store}>
    {/* <React.StrictMode> */}
    <Suspense fallback={<div> Loading ...</div>}>
      <App />{" "}
    </Suspense>
    {/* </React.StrictMode>{" "} */}
  </ReduxProvider>,
  document.getElementById("root")
);
