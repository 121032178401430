import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Carousel as ReactCarousel } from "primereact/carousel";
import Carousel from "react-elastic-carousel";
import ModalVideo from "react-modal-video";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from "../../../redux/actions/sportActions";
import * as api from "../../../api/susfApi";

import { Dialog } from 'primereact/dialog';
import DocViewer, { BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer } from "react-doc-viewer";
import { format } from "date-fns";
import { arSA } from 'date-fns/locale';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

function Competitions(props) {
  const { id, ses } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.sessons || props.sessons.length <= 0) {
      props.getSesson();
    }
  }, [])

  useEffect(() => {
    if (props.sessons && props.sessons.length > 0) {
      setSeasonVisiableNumber(props.sessons.length < 4 ? props.sessons.length : 4);
      let currentSeasson = {};
      if (ses) {
        currentSeasson = props.sessons.find((element) => {
          return element._id === ses;
        });
      } else {
        currentSeasson = props.sessons.find((element) => {
          return element.isCurrent === true;
        });
      }
      setActiveSeason(currentSeasson._id);
      setActiveSeasonName(currentSeasson.name);

      if (id) {
        setActiveSport(id);
      }
    }
  }, [props.sessons])


  const [sports, setSeasonSports] = useState([]);
  const [seasonVisiableNumber, setSeasonVisiableNumber] = useState(4);
  const [selectedSeason, setActiveSeason] = useState('');
  const [selectedSeasonName, setActiveSeasonName] = useState('');
  const [selectedSport, setActiveSport] = useState('');
  const [selectedSportName, setActiveSportName] = useState('');
  const [selectedSportType, setActiveSportType] = useState(1);

  const [selectedExcellenceGrade, setExcellenceGrade] = useState('');
  const [mediaTabSelected, setMediaTabSelected] = useState('img');
  const [setsTabSelected, setSetsTabSelected] = useState('');
  const [matchTeams, setTabSelectedData] = useState([]);



  const [news, setNews] = useState([]);
  const [photoList, setphotoList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [journalsList, setJournalList] = useState([]);
  const [prints, setPublicationList] = useState([]);
  const [gathering, setGatheringList] = useState([]);
  const [university, setUniversityList] = useState([]);

  const [compRound, setCompRoundList] = useState([]);

  // Get sports after select
  useEffect(() => {
    if (props.sessons && props.sessons.length > 0 && selectedSeason) {
      const seasonData = props.sessons.find((element) => {
        return element._id === selectedSeason;
      });

      const seasonSportData = seasonData.competions.map((elem) => {
        return { id: elem._id, name: elem.name, date: elem.yearName, image: elem.image, compType: elem.compType }
      });
      setSeasonSports(seasonSportData);
    }
  }, [selectedSeason])


  useEffect(() => {
    if (selectedSeason) {
      setActiveSeasonName(
        props.sessons.find((element) => {
          return element._id === selectedSeason;
        }).name
      );

      setGatheringList(props.sessons.find((element) => {
        return element._id === selectedSeason;
      }).gatheringPlaces)
    }
  }, [selectedSeason])

  useEffect(() => {
    if (selectedSport) {
      const seassonData = props.sessons.find((element) => {
        return element._id === selectedSeason;
      });

      const sportData = seassonData.competions.find((elem) => {
        return elem._id === selectedSport
      });

      setActiveSportName(sportData.name);

      setActiveSportType(sportData.sportType);

      api.getSportData('id=' + selectedSport + "&sportType=" + sportData.sportType).then((result) => {
        if (result && result.successful) {
          setNews(result.data.news);
          setphotoList(result.data.images);
          setVideoList(result.data.vidios);
          setJournalList(result.data.newspaper);
          setPublicationList(result.data.publication);
          setUniversityList(result.data?.compUniversity?.universityData);

          setCompRoundList(result.data?.compRound);

          if (sportData.sportType === 2 && result.data?.compRound.length > 0) {
            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>   " + compRound[0]._id)
            setSetsTabSelected(result.data?.compRound[0]._id);
            setTabSelectedData(result.data?.compRound[0].matchTeam);
          }

          if (result.data?.compUniversity) {
            let allGathering = props.sessons.find((element) => {
              return element._id === selectedSeason;
            }).gatheringPlaces;

            if (allGathering) {
              let competionGather = allGathering.find((element) => {
                return element._id === result.data?.compUniversity?.gatheringId;
              })

              if (competionGather) {
                setGatheringList([competionGather]);
              }
            }
          }
        }
      })
    }
  }, [selectedSport])

  useEffect(() => {
    if (compRound) {
      let compRoundData = compRound.find((element) => {
        return element._id === setsTabSelected;
      });
      if (compRoundData) {
        setTabSelectedData(compRoundData.matchTeam);
      }
    }
  }, [setsTabSelected])

  // Define Video Id to play video
  const [isOpen, setisOpen] = useState(false);
  const openModal = () => {
    setisOpen(!isOpen);
  };

  const [videoID, setVideoID] = useState('');
  const playVideo = (id) => {
    setVideoID(id);
    setisOpen(!isOpen);
  }

  // Seasons Slider Settings
  const seasons = props?.sessons?.map((elem) => {
    return { id: elem._id, name: elem.name, date: elem.yearName, image: elem.image, isCurrent: elem.isCurrent }
  });


  const seasonsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const seasonsTemplate = (season) => {
    return (
      <div className={`season-item ${selectedSeason == season.id ? "active" : ""}`}
        onClick={() => setActiveSeason(season.id)}>
        <div className="season-item-content">
          <div className="date">
            {season.date} - {season.name}
          </div>
          <div className="title">
            <img src={season.image} alt="icon" width="100px" />
            {/* <h5>{season.name}</h5> */}
          </div>
          <div className="traingle-shape"></div>
        </div>
      </div>
    );
  };
  // End Seasons Slider Settings

  // Sports Slider Settings
  const sportsBreakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 2 },
    { width: 850, itemsToShow: 4 },
    { width: 1150, itemsToShow: 5 },
    { width: 1200, itemsToShow: 7 },
    { width: 1400, itemsToShow: 8 }
  ]
  // End Sports Slider Settings

  // Prints Slider Settings
  const printsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const printsTemplate = (print) => {
    return (
      <div className="prints-item">
        <div className="prints-item-content">
          <div className="overlay">
            <h4>{print.name}</h4>

            <a href="#!" className="downloadPdf" onClick={() => viewDoc(print.attachKey, print.attachName)}>
              <img
                src="/content/images/icons/download.svg"
                alt="تحميل"
              />
            </a>
          </div>
          <div className="img">
            <img
              src={print.image}
              alt="prints"
              className="new-image"
            />
          </div>
        </div>
      </div>
    );
  };

  const gatheringResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const gatheringTemplate = (gathering) => {
    return (
      <div className="info">
        <div className="top">{gathering.cityName}</div>
        <div className="bottom">

          <div className="right">
            <p>إلى</p>
            <p>
              {
                gathering?.dateTo ? (
                  <>
                    {format(Date.parse(gathering?.dateTo), "dd MMMM, yyyy", { locale: arSA })}
                  </>
                ) : ''
              }
            </p>
          </div>
          <div className="left">
            <p>من</p>
            <p>
              {
                gathering?.dateFrom ? (
                  <>
                    {format(Date.parse(gathering?.dateFrom), "dd MMMM, yyyy", { locale: arSA })}
                  </>
                ) : ''
              }
            </p>
          </div>
        </div>
      </div >
    );
  };

  const [docs, setDocViewer] = useState([]);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const onHide = () => {
    setDisplayResponsive(false);
  }
  const viewDoc = (attachKey, attachName) => {
    api.getFile(attachKey, attachName, "publication").then((response) => {
      // setDocViewer(response.data?.url);//[{ uri: response.data?.url }]);
      // setDisplayResponsive(true);
      window.open(response.data?.url,"_self");
    });
  }
  // End Prints Slider Settings
  const displaySchedulerHeader = () => {
    if (selectedSportType === 2) {
      return (
        <div className="tabs-header">
          {compRound.map(element => (
            <>
              <a href="#!" className={setsTabSelected === element._id ? 'active' : ''}
                onClick={() => setSetsTabSelected(element._id)}>
                {element.name}
              </a>
            </>
          ))
          }
        </div>
      );
    }
    return '';
  }

  const displayScheduler = () => {
    if (selectedSportType === 1) {
      return displayIndividualTeam();
    }
    return displayMatchData();
  }

  const displayIndividualTeam = () => {
    return (
      <ul className="list-unstyled">
        {compRound.map(element => (
          <li>
            <div className="box-head2">
              {element.name}
            </div>
            <div className="box-body2">
              <div className="left">
                <span>
                  {
                    element?.dateFrom ? (
                      <>
                        <strong>
                          {format(Date.parse(element?.dateFrom), "dd MMMM, yyyy", { locale: arSA })}
                        </strong>
                        {/* <small>
                  {format(Date.parse(element?.dateFrom), "hh:mm a", { locale: arSA })}
                  </small> */}
                      </>
                    ) : ''
                  }
                </span>
              </div>
              <div className="middle">|</div>
              <div className="right">
                <span>
                  {
                    element?.dateTo ? (
                      <>
                        <strong>
                          {format(Date.parse(element?.dateTo), "dd MMMM, yyyy", { locale: arSA })}
                        </strong>
                        {/* <small>
                  {format(Date.parse(element?.dateTo), "hh:mm a", { locale: arSA })}
                  </small> */}
                      </>
                    ) : ''
                  }
                </span>
              </div>
            </div>
          </li>
        ))
        }
      </ul>
    )
  }

  const displayMatchData = () => {
    return (
      <ul className="list-unstyled">
        {matchTeams.map(element => (
          <li>
            <div className="box-head">
              <div className="left">
                {element.isTeams ? getUniversityLogo(element.team1Id) : ''}
                <div className="title">  {element.isTeams ? element.team1Name : element.team1Str} </div>
              </div>
              <span className="vs">vs</span>
              <div className="right">
                <div className="title">   {element.isTeams ? element.team2Name : element.team2Str} </div>
                {element.isTeams ? getUniversityLogo(element.team2Id) : ''}
              </div>
            </div>
            <div className="box-body">
              <div className="left">

              </div>
              <div className="middle">
                {
                  element?.dateTime ? (
                    <>
                      {format(Date.parse(element?.dateTime), "dd MMMM, yyyy", { locale: arSA })}
                    </>
                  ) : ''
                }
              </div>
              <div className="right">

              </div>
            </div>
          </li>
        ))
        }
      </ul>
    )
  }

  const getUniversityLogo = (univerId) => {
    if (univerId) {
      let univerData = university.find((element) => {
        return element._id === univerId;
      });
      if (univerData) {
        return (
          <img src={univerData.image} alt="" />
        );
      }
    }
    return '';
  }

  const getImages = () => {
    if (photoList.length > 0) {
      return (photoList.map(photo => (
        <div className="col-sm-12 col-md-6 col-lg-4" key={photo._id}>
          <div className="media-box">
            <img
              src={photo.image}
              alt="main-img"
              className="main-img"
            />
          </div>
        </div>
      )));
    } else {
      return (
        <div className="emptyData">
          <img src="/content/images/nodata.png" alt="no data" />
          <span>لا يوجد</span>
        </div>
      );
    }
  }

  return (
    <>
      <Dialog header="مستعرض الملفات" visible={displayResponsive} draggable={false} maximized={true} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} style={{ width: '75vw' }} >
      <iframe src={docs} className="iframe"></iframe>
        {/* <DocViewer config={{ header: { disableHeader: true, disableFileName: true, retainURLParams: false } }}
          documents={docs} pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer]} style={{ width: '70vw', height: '60vh' }} /> */}
      </Dialog>

      <div className="competitions">
        <div className="banner">
          <div className="overlay">
            <h3>المسابقات</h3>
            <h5>المسابقات</h5>
          </div>
        </div>
        <div className="seasons-sports">
          <div className="seasons">
            {
              seasons.length > 0 ? (
                <>
                  <ReactCarousel
                    value={seasons}
                    itemTemplate={seasonsTemplate}
                    numVisible={seasonVisiableNumber}
                    numScroll={1}
                    responsiveOptions={seasonsResponsiveOptions}
                  //autoplayInterval={4000}
                  // circular={true}
                  ></ReactCarousel>
                </>
              ) : ''
            }
          </div>
          <div className="sports">
            <Carousel itemsToShow={4} isRTL={true} enableAutoPlay={true}
              pagination={true} showArrows={false}
              breakPoints={sportsBreakPoints}>
              {/* {sports  ? (
                <> */}
              {sports.map(item => (
                <div className={`sport-item ${selectedSport == item.id ? "active" : ""}`}
                  onClick={() => setActiveSport(item.id)}
                  key={item.id}>
                  <div className="icon">
                    <img src={item.image} alt="icon" />

                  </div>
                  <h6>
                    {item.name}&nbsp;-&nbsp;
                    {item.compType == 1 ? "طلاب" : "طالبات"}
                  </h6>
                </div>
              ))}
              {/* </>
              ) : ''} */}

            </Carousel>
          </div>
          <div className="selectedSport">
            <span>{selectedSeasonName}</span>
            <span>{selectedSportName}</span>
          </div>
        </div>
        <div className="season-details">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="side">
                  <div className="latest-news">
                    <h3 className="title">الاخبار</h3>
                    {news.length > 0 ? (
                      <>
                        {news.map(item => (
                          <Link to={`/details/${item.id}`} >
                            <div className="item">
                              <h3 >
                                <ResponsiveEllipsis
                                  unsafeHTML={item.title}
                                  maxLine='3'
                                  ellipsis='...'
                                />
                                {/* className="climp num3"{item.title} */}
                              </h3>
                              <img
                                src={item.image}
                                alt="news"
                                className="new-image"
                              />
                            </div>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="emptyData">
                          <img src="/content/images/nodata.png" alt="no data" />
                          <span>لا يوجد</span>
                        </div>
                      </>
                    )
                    }



                  </div>
                  <div className="unversities">
                    <div className="title">
                      <span className="badge">{university.length}</span>
                      الجامعات المشاركة
                    </div>
                    {university.length > 0 ? (
                      <>
                        <ul className="list-unstyled">
                          {university.map(item => (
                            <li className="oneonly" >
                              <img src={item.image} alt="" />
                              <div className="title1"> {item.name}</div>
                              {/* {item.name.length > 22 ? item.name.substring(0, 23) : item.name} */}
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <>
                        <div className="emptyData">
                          <img src="/content/images/nodata.png" alt="no data" />
                          <span>لا يوجد</span>
                        </div>
                      </>
                    )
                    }

                  </div>
               
                </div>
              </div>
              <div className="col-md-9">
                <div className="media-center">
                  <div className="title">
                    <h3>وسائط متعددة</h3>
                    <div className="tabs">
                      <div className="tabs-header">
                        <a href="#!" className={mediaTabSelected === 'img' ? 'active' : ''}
                          onClick={() => setMediaTabSelected('img')}>
                          الصور
                        </a>
                        <a href="#!" className={mediaTabSelected === 'video' ? 'active' : ''}
                          onClick={() => setMediaTabSelected('video')}>
                          الفيديوهات
                        </a>
                        <a href="#!" className={mediaTabSelected === 'journal' ? 'active' : ''}
                          onClick={() => setMediaTabSelected('journal')}>
                          اخبار الصحف
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="row">
                      <div className="row">
                        {mediaTabSelected === 'img' ? (
                          getImages()
                        ) : ''}
                        {mediaTabSelected === 'video' ? (
                          <>
                            {videoList.map(video => (
                              <div className="col-sm-12 col-md-6 col-lg-4" key={video._id}>
                                <div className="media-box" onClick={() => playVideo(video.url)}>
                                  <img
                                    src={video.image}
                                    alt="main-img"
                                    className="main-img"
                                  />
                                  <img
                                    src="/content/images/icons/play_outline.svg"
                                    alt="play"
                                    className="play-img"
                                  />
                                </div>
                              </div>
                            ))}
                          </>
                        ) : ''}
                        {mediaTabSelected === 'journal' ? (
                          <>
                            {journalsList.map(file => (
                              <div className="col-sm-12 col-md-6 col-lg-6" key={file.id}>
                                <div className="file-box">
                                  <a href={file.newsLink} target="_blank">
                                    <img src="/content/images/icons/ext-link.svg" alt="link" />
                                  </a>
                                  <img
                                    src={file.image}
                                    alt="main-img"
                                    className="main-img"
                                  />
                                  <div className="info">
                                    <Link to={`/details/${file.id}`} >
                                      <h3>{file.title}</h3>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : ''}

                      </div>
                    </div>
                  </div>
                </div>
                {compRound && compRound.length > 0 ?
                  (
                    <>
                      <div className="matches">
                        <div className="title">
                          <h3>المباريات</h3>
                          <div className="tabs">
                            {displaySchedulerHeader()}
                          </div>
                        </div>
                        <div className="tab-content">
                          {displayScheduler()}
                        </div>
                      </div>
                    </>
                  ) : ''
                }

                <div className="gathering-prints">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="prints">
                          <div className="title">
                            <h3>
                              المطبوعات
                              <img src="/content/images/icons/prints.svg" alt="" />
                            </h3>
                            <h5>
                              &nbsp; &nbsp;
                              {/* يمكنك سرد الأحداث الخاصة بك مع نظام إدارة الأحداث المتقدم */}
                            </h5>
                          </div>
                          <ReactCarousel
                            value={prints}
                            itemTemplate={printsTemplate}
                            numVisible={1}
                            numScroll={1}
                            responsiveOptions={printsResponsiveOptions}
                            autoplayInterval={4000}
                            circular={true}
                          ></ReactCarousel>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="gathering">
                          <div className="title">
                            <h3>
                              <img src="/content/images/icons/crowd-of-users.svg" alt="crowd" />
                              التجمعات
                            </h3>
                          </div>
                          <ReactCarousel
                            value={gathering}
                            itemTemplate={gatheringTemplate}
                            numVisible={1}
                            numScroll={1}
                            responsiveOptions={gatheringResponsiveOptions}
                            autoplayInterval={4000}
                            circular={true}
                          ></ReactCarousel>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoID}
        onClose={openModal}
      />
    </>
  );

}


function mapStateToProps(state) {
  return {
    sessons: state.sport.sessons
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSesson: bindActionCreators(action.getSessonsApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Competitions);