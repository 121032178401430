
import React, { useState, useEffect } from 'react';
// import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

function Footer(props: any) {
  const sharedData = props.sharedData?.sharedData;
  const usefulLinks = props.sharedData?.linksData;

  const [advertise, setAdvertiseIsClose] = useState(false);

  useEffect(() => {
    if (props.sharedData && props.sharedData.sharedData
      && props.sharedData.sharedData.importantMsg && props.sharedData.sharedData.importantMsg !== "") {
      setAdvertiseIsClose(true);
    }
  }, [props.sharedData])

  return (
    <>
      <div className="main-footer">
        <div className="topfooter">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-md-4">
                <img src="/content/images/icons/2030.png" alt="" />
                <div className="social">
                  <h4>تابعنا علي حسابات التواصل الإجتماعي</h4>
                  {/* <a href={sharedData?.facebook} target="_blank">
                    <img src="/content/images/icons/facebook.svg" alt="" />
                  </a> */}
                  <a href={sharedData?.youtube} target="_blank">
                    <img src="/content/images/icons/youtube.svg" alt="" />
                  </a>
                  <a href={sharedData?.instagram} target="_blank">
                    <img src="/content/images/icons/instagram.svg" alt="" />
                  </a>
                  <a href={sharedData?.twitter} target="_blank">
                    <img src="/content/images/icons/twitter.svg" alt="" />
                  </a>
                  <a href={sharedData?.snapchat} target="_blank">
                    <img src="/content/images/icons/snapchat.svg" alt="" />
                  </a>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="contacts">
                  <h4>معلومات التواصل</h4>
                  <ul className="list-unstyled">
                    <li>
                      <img src="/content/images/footer-social/pin.svg" alt="social" />
                      {sharedData?.address}
                    </li>
                    <li>
                      <img src="/content/images/footer-social/phone-call.svg" alt="social" />
                      {sharedData?.mobile}
                    </li>
                    <li>
                      <img src="/content/images/footer-social/email.svg" alt="social" />
                      {sharedData?.email}
                    </li>
                    <li>
                      <img src="/content/images/footer-social/twitter.svg" alt="social" />
                      {sharedData?.twitter}
                    </li>
                    <li>
                      <img src="/content/images/footer-social/youtube.svg" alt="social" />
                      {sharedData?.youtube}
                    </li>
                    <li>
                      <img src="/content/images/footer-social/instagram.svg" alt="social" />
                      {sharedData?.instagram}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="links">
                  <h4>روابط مفيدة</h4>
                  <ul className="list-unstyled">
                    {usefulLinks && usefulLinks.length > 0 ?
                      (
                        <>
                          {
                            usefulLinks.slice(0, 5).map((item: any) => (
                              <li key={item.name}>
                                <a href={item.link} target="_blank">
                                  {item.name}
                                </a>
                              </li>
                            ))
                          }
                        </>) : ''
                    }
                  </ul>
                  <ul className="list-unstyled">
                    {usefulLinks && usefulLinks.length > 5 ?
                      (
                        <>
                          {
                            usefulLinks.slice(5, 10).map((item: any) => (
                              <li key={item.name}>
                                <a href={item.link} target="_blank">
                                  {item.name}
                                </a>
                              </li>
                            ))
                          }
                        </>) : ''
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <p className="copyright">
                  جميع الحقوق محفوظة لـوزارة التعليم - المملكة العربية السعودية
                </p>
              </div>
            </div>
          </div>
        </div>
        {advertise && (
          <div className="bottomfooter">
            <img src="/content/images/icons/alarm.svg" alt="" />
            <div className="footer-news">
              {props.sharedData?.sharedData?.importantMsg}
              
            </div>
            {/* <NavLink to="/news" className="btn">مشاهدة المزيد</NavLink> */}
            <button className="closeBTN" onClick={() => setAdvertiseIsClose(false)}>×</button>
          </div>
        )}

      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    sharedData: state.home.sharedData
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);