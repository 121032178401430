import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Carousel as ReactCarousel } from "primereact/carousel";
import Carousel from "react-elastic-carousel";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from "../../../redux/actions/sportActions";
import * as api from "../../../api/susfApi";
import { format } from "date-fns";
import { arSA } from 'date-fns/locale';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import DocViewer, { BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer } from "react-doc-viewer";

function Results(props: any) {
  // class Results extends React.Component {

  const { id, ses } = useParams<any>();

  const [sports, setSeasonSports] = useState<any>([]);
  const [seasonVisiableNumber, setSeasonVisiableNumber] = useState(4);
  const [selectedSeason, setActiveSeason] = useState('');
  const [selectedSeasonName, setActiveSeasonName] = useState('');
  const [selectedSport, setActiveSport] = useState('');
  const [selectedSportName, setActiveSportName] = useState('');
  const [selectedSportType, setActiveSportType] = useState(1);

  const [news, setNews] = useState<any>([]);
  const [university, setUniversityList] = useState<any>([]);

  const [selectedExcellenceGrade, setExcellenceGrade] = useState('silver');
  const [honerName, setHonerName] = useState('');
  const [honerImage, setHonerImage] = useState('');

  const [resultData, setResultData] = useState<any>([]);
  const [compRound, setCompRoundList] = useState<any>([]);
  const [setsTabSelected, setSetsTabSelected] = useState('');
  const [matchTeams, setTabSelectedData] = useState<any>([]);

  const [teamsOrder, setTeamsOrderData] = useState<any>([]);

  const [teamsPoints, setTeamsPointsData] = useState<any>([]);

  const [prints, setPublicationList] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.sessons || props.sessons.length <= 0) {
      props.getSesson();
    }
  }, [])

  useEffect(() => {
    if (props.sessons && props.sessons.length > 0) {
      setSeasonVisiableNumber(props.sessons.length < 4 ? props.sessons.length : 4);
      let currentSeasson: any = {};
      if (ses) {
        currentSeasson = props.sessons.find((element: any) => {
          return element._id === ses;
        });
      } else {
        currentSeasson = props.sessons.find((element: any) => {
          return element.isCurrent === true;
        });
      }
      setActiveSeason(currentSeasson._id);
      setActiveSeasonName(currentSeasson.name);

      if (id) {
        setActiveSport(id);
      }
    }
  }, [props.sessons])

  // Get sports after select
  useEffect(() => {
    setExcellenceGrade('');
    setHonerName('');
    setHonerImage('');

    setTeamsPointsData([]);
    setTeamsOrderData([]);

    if (props.sessons && props.sessons.length > 0 && selectedSeason) {
      const seasonData = props.sessons.find((element: any) => {
        return element._id === selectedSeason;
      });

      const seasonSportData = seasonData.competions.map((elem: any) => {
        return { id: elem._id, name: elem.name, date: elem.yearName, image: elem.image, compType: elem.compType }
      });
      setSeasonSports(seasonSportData);
    }
  }, [selectedSeason]);


  useEffect(() => {
    if (selectedSport) {
      setExcellenceGrade('');
      setHonerName('');
      setHonerImage('');

      setTeamsPointsData([]);
      setTeamsOrderData([]);

      const seassonData = props.sessons.find((element: any) => {
        return element._id === selectedSeason;
      });

      const sportData = seassonData.competions.find((elem: any) => {
        return elem._id === selectedSport
      });

      setActiveSportName(sportData.name);
      setActiveSportType(sportData.sportType);
      api.getSportData('id=' + selectedSport + "&sportType=" + sportData.sportType).then((result) => {
        if (result && result.successful) {
          setNews(result.data.news);
          setUniversityList(result.data?.compUniversity?.universityData);
        }
      });

      api.getSportResult('id=' + selectedSport + "&sportType=" + sportData.sportType).then((result) => {
        if (result && result.successful) {
          // console.log(result.data?.result)
          setResultData(result.data?.result);
          setCompRoundList(result.data?.result);
          if (result.data?.attachment) setPublicationList(result.data?.attachment);
          //  console.log(sportData)
          if (sportData && sportData.sportType === 2 && result.data?.result?.length > 0) {
            setSetsTabSelected(result.data?.result[0]._id);
            setTabSelectedData(result.data?.result[0].matchTeam);
            setTeamsPointsData(result.data.detailsData);

            //setTeamsOrderData(result.data.teamsOrder);
          }
        
          if (sportData.sportType === 1) {
            if (result.data.result && result.data.result.length > 0) {
              setExcellenceGrade('gold');
              setHonerName(result.data.result[0].universityData.name);
              setHonerImage(result.data.result[0].universityData.image);
            }
          }
          else if (sportData.sportType === 2) {
            if (result.data.teamsOrder && result.data.teamsOrder.length > 0) {
              setExcellenceGrade('gold');

              setHonerName(result.data.teamsOrder[0].universityName);
              setHonerImage(getUniversityLogoStr(result.data.teamsOrder[0].university));

              setTeamsOrderData(result.data.teamsOrder);
            }
          }
        }
      });

    }
  }, [selectedSport]);


  useEffect(() => {
    if (compRound) {
      let compRoundData = compRound.find((element: any) => {
        return element._id === setsTabSelected;
      });
      if (compRoundData) {
        setTabSelectedData(compRoundData.matchTeam);
      }
    }
  }, [setsTabSelected]);

  const setExcellenceGradeFun = (className: any) => {
    if (selectedSportType === 1) {
      if (resultData && resultData.length > 0) {
        setExcellenceGrade(className);
        switch (className) {
          case "gold":
            setHonerName(resultData[0].universityData.name);
            setHonerImage(resultData[0].universityData.image);
            break;
          case "bronze":
            setHonerName(resultData[1].universityData.name);
            setHonerImage(resultData[1].universityData.image);
            break;
          case "silver":
            setHonerName(resultData[2].universityData.name);
            setHonerImage(resultData[2].universityData.image);
            break;
          default:
            setHonerName('');
            setHonerImage('');
            break;
        }
      }
    } else if (selectedSportType === 2) {
      if (teamsOrder && teamsOrder.length > 0) {
        setExcellenceGrade(className);
        switch (className) {
          case "gold":
            setHonerName(teamsOrder[0].universityName);
            setHonerImage(getUniversityLogoStr(teamsOrder[0].university));
            break;
          case "bronze":
            setHonerName(teamsOrder[1].universityName);
            setHonerImage(getUniversityLogoStr(teamsOrder[1].university));
            break;
          case "silver":
            setHonerName(teamsOrder[2].universityName);
            setHonerImage(getUniversityLogoStr(teamsOrder[2].university));
            break;
          default:
            setHonerName('');
            setHonerImage('');
            break;
        }
      }
    }
  }

  // Seasons Slider Settings
  const seasons = props?.sessons?.map((elem: any) => {
    return { id: elem._id, name: elem.name, date: elem.yearName, image: elem.image, isCurrent: elem.isCurrent }
  });


  const seasonsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const seasonsTemplate = (season: any) => {
    return (
      <div className={`season-item ${selectedSeason === season.id ? "active" : ""}`}
        onClick={() => setActiveSeason(season.id)}>
        <div className="season-item-content">
          <div className="date">
            {season.date} - {season.name}
          </div>
          <div className="title">
            <img src={season.image} alt="icon" width="100px" />
            {/* <h5>{season.name}</h5> */}
          </div>
          <div className="traingle-shape"></div>
        </div>
      </div>
    );
  };
  // End Seasons Slider Settings

  // Sports Slider Settings

  const sportsBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 850, itemsToShow: 4 },
    { width: 1150, itemsToShow: 5 },
    { width: 1200, itemsToShow: 7 },
    { width: 1400, itemsToShow: 8 }
  ]
  // End Sports Slider Settings

  // Prints Slider Settings
  const printsResponsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const printsTemplate = (print: any) => {
    return (
      <div className="prints-item">
        <div className="prints-item-content">
          <div className="overlay">
            <h4>{print.name}</h4>

            <a href="#!" className="downloadPdf" onClick={() => viewDoc(print.attachKey, print.attachName)}>
              <img
                src="/content/images/icons/download.svg"
                alt="تحميل"
              />
            </a>
          </div>
          <div className="img">
            {/* <img
              src={print.image}
              alt="prints"
              className="new-image"
            /> */}
          </div>
        </div>
      </div>
    );
  };
  // End Prints Slider Settings

  // render() {

  // Result
  const displaySchedulerHeader = () => {
    if (selectedSportType === 2) {
      return (
        <div className="title">
          <h3>المباريات</h3>
          <div className="tabs">
            <div className="tabs-header">
              {resultData.map((element: any) => (
                <>
                  <a href="#!" className={setsTabSelected === element._id ? 'active' : ''}
                    onClick={() => setSetsTabSelected(element._id)}>
                    {element.name}
                  </a>
                </>
              ))
              }
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  const displayScheduler = () => {
    if (selectedSportType === 1) {
      return displayIndividualTeam();
    }
    return displayMatchData();
  }

  const displayIndividualTeam = () => {
    return (
      <ul className="list-unstyled">
        {resultData.map((element: any, index: number) => (
          <li>
            <div className="box-head2 flex">
              <div className="left">
                <img src={element?.universityData?.image} alt="" />
                {element?.universityData?.name}
              </div>
              <span className="points">{index + 1}</span>
            </div>

          </li>
        ))
        }
      </ul>
    )
  }

  const displayMatchData = () => {
    return (
      <ul className="list-unstyled">
        {matchTeams.map((element: any) => (
          <li>
            <div className="box-head">
              <div className="left">
                {element.isTeams ? getUniversityLogo(element.team1Id) : ''}
                <div className="title">  {element.isTeams ? element.team1Name : element.team1Str}</div>
              </div>
              <span className="vs">vs</span>
              <div className="right">
                <div className="title"> {element.isTeams ? element.team2Name : element.team2Str}</div>
                {element.isTeams ? getUniversityLogo(element.team2Id) : ''}
              </div>
            </div>
            <div className="box-body">
              <div className="left">
                {
                  element?.dateTime ? (
                    <>
                      {format(Date.parse(element?.dateTime), "dd MMMM, yyyy", { locale: arSA })}
                    </>
                  ) : ''
                }
              </div>
              <div className="middle">
                {element.scoreVisible ? element.team1Score : '-'}
                &nbsp;
                :
                &nbsp;
                {element.scoreVisible ? element.team2Score : '-'}

              </div>
              <div className="right">
                {getMatchStatus(element)}
              </div>
            </div>
          </li>
        ))
        }
      </ul>
    )
  }

  const getMatchStatus = (element: any) => {
    if (element.status) {
      if (element.status === 1) {
        return (
          <span className="match-status postpone">مؤجلة</span>
        );
      } else if (element.status === 2) {
        return (
          <span className="match-status canceled">ألغيت</span>
        );
      }
    } else {
      if (element.team1Score >= 0 && element.team2Score >= 0) {
        return (
          <span className="match-status done">إنتهت</span>
        );
      } else {
        return (
          <span className="match-status notyet">لم تبدأ</span>
        );
      }
    }
  }

  const getUniversityLogo = (univerId: any) => {
    if (univerId) {
      let univerData = university.find((element: any) => {
        return element._id === univerId;
      });
      if (univerData) {
        return (
          <img src={univerData.image} alt="" />
        );
      }
    }
    return '';
  }

  const getUniversityLogoStr = (univerId: any) => {

    if (univerId) {
      let univerData = university.find((element: any) => {
        return element._id === univerId;
      });
      if (univerData) {
        return univerData.image;
      }
    }
    return '';
  }

  const onIndexTemplate = (data: any, props: any) => {
    return props.rowIndex + 1;
  }
  const displayTeamsPoints = () => {
    return (

      <DataTable value={teamsPoints} let-i="rowIndex"

        // className="p-datatable-responsive-demo"
        emptyMessage="لا يوجد">
        <Column field="Index" header="#" body={onIndexTemplate} style={{ width: '8%' }} />
        <Column field="name" header="الفريق" style={{ width: '50%' }}></Column>
        <Column field="play" header="لعب" style={{ width: '8%' }}></Column>
        <Column field="win" header="فوز" style={{ width: '8%' }}></Column>
        <Column field="lose" header="خسارة" style={{ width: '8%' }}></Column>
        <Column field="draw" header="تعادل" style={{ width: '8%' }}></Column>
        <Column field="point" header="النقاط" style={{ width: '8%' }}></Column>
      </DataTable>
    );
  }

  const displayTeamsOrder = () => {
    return (

      <DataTable value={teamsOrder} let-i="rowIndex"

        // className="p-datatable-responsive-demo"
        emptyMessage="لا يوجد">
        <Column field="Index" header="#" body={onIndexTemplate} style={{ width: '10%' }} />
        <Column field="universityName" header="الفريق" style={{ width: '90%' }}></Column>

      </DataTable>
    );
  }
  //end result

  const [docs, setDocViewer] = useState<any>([]);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const onHide = () => {
    setDisplayResponsive(false);
  }
  const viewDoc = (attachKey: any, attachName: any) => {
    api.getFile(attachKey, attachName, "publication").then((response) => {
      // setDocViewer(response.data?.url);//[{ uri: response.data?.url }]);
      // setDisplayResponsive(true);
      window.open(response.data?.url, "_self");
    });
  }
  return (
    <>
      <Dialog header="مستعرض الملفات" visible={displayResponsive} draggable={false} maximized={true} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} style={{ width: '75vw' }} >
        <iframe src={docs} className="iframe"></iframe>
        {/* <DocViewer config={{ header: { disableHeader: true, disableFileName: true, retainURLParams: false } }}
          documents={docs} pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer]} style={{ width: '70vw', height: '60vh' }} /> */}
      </Dialog>
      <div className="competitions result-page">
        <div className="banner">
          <div className="overlay">
            <h3>النتائج</h3>
            <h5>النتائج</h5>
          </div>
        </div>
        <div className="seasons-sports">
          <div className="seasons">
            {
              seasons.length > 0 ? (
                <>
                  <ReactCarousel
                    value={seasons}
                    itemTemplate={seasonsTemplate}
                    numVisible={seasonVisiableNumber}
                    numScroll={1}
                    responsiveOptions={seasonsResponsiveOptions}
                    circular={true}
                  ></ReactCarousel>
                </>
              ) : ''
            }
          </div>
          <div className="sports">
            <Carousel itemsToShow={4} isRTL={true} enableAutoPlay={true}
              pagination={true} showArrows={false}
              breakPoints={sportsBreakPoints}>
              {sports.map((item: any) => (
                <div className={`sport-item ${selectedSport === item.id ? "active" : ""}`}
                  onClick={() => setActiveSport(item.id)}
                  key={item.id}>
                  <div className="icon">
                    <img src={item.image} alt="icon" />
                  </div>
                  <h6>
                    {item.name}&nbsp;-&nbsp;
                    {item.compType == 1 ? "طلاب" : "طالبات"}
                  </h6>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="selectedSport">
            <span>{selectedSeasonName}</span>
            <span>{selectedSportName}</span>
          </div>
        </div>
        <div className="season-details">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="side">
                  <div className="latest-news">
                    <h3 className="title">الاخبار</h3>
                    {news.length > 0 ? (
                      <>
                        {news.map((item: any) => (
                          <Link to={`/details/${item.id}`} >
                            <div className="item">
                              <h3 className="climp num3">{item.title}</h3>
                              <img
                                src={item.image}
                                alt="news"
                                className="new-image"
                              />
                            </div>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="emptyData">
                          <img src="/content/images/nodata.png" alt="no data" />
                          <span>لا يوجد</span>
                        </div></>
                    )
                    }
                  </div>
                  <div className="unversities">
                    <div className="title">
                      <span className="badge">{university.length}</span>
                      الجامعات المشاركة
                    </div>
                    {university.length > 0 ? (
                      <>
                        <ul className="list-unstyled">
                          {university.map((item: any) => (
                            <li className="oneonly">
                              <img src={item.image} alt="" />
                              <div className="title1"> {item.name}</div>
                              {/* {item.name.length > 22 ? item.name.substring(0, 23) : item.name} */}
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <>
                        <div className="emptyData">
                          <img src="/content/images/nodata.png" alt="no data" />
                          <span>لا يوجد</span>
                        </div>
                      </>
                    )
                    }

                  </div>
                  <div className={`excellence ${selectedExcellenceGrade}`}>
                    <div className="title">
                      <h3>
                        <img src="./content/images/icons/live-streaming.svg" alt="" />
                        التميز
                      </h3>
                    </div>
                    <div className="box-body">
                      <div className="box">
                        {honerImage !== '' ? (<>

                          <img src={honerImage} alt="no data" />
                        </>) : (
                          <>
                            <img src="/content/images/nodata.png" alt="no data" />
                          </>
                        )}
                        <span>{honerName}</span>
                      </div>
                    </div>
                    <div className="grades">
                      <span className="silver" onClick={() => setExcellenceGradeFun('silver')}>3</span>
                      <span className="bronze" onClick={() => setExcellenceGradeFun('bronze')}>2</span>
                      <span className="gold" onClick={() => setExcellenceGradeFun('gold')}>1</span>
                    </div>
                  </div>

                  {prints.length > 0 ? (
                    <>
                      <div className="prints">
                        <div className="prints">
                          <div className="title">
                            <h3>
                              المطبوعات
                              <img src="/content/images/icons/prints.svg" alt="" />
                            </h3>
                            <h5>
                              &nbsp; &nbsp;
                              {/* يمكنك سرد الأحداث الخاصة بك مع نظام إدارة الأحداث المتقدم */}
                            </h5>
                          </div>
                          <ReactCarousel
                            value={prints}
                            itemTemplate={printsTemplate}
                            numVisible={1}
                            numScroll={1}
                            responsiveOptions={printsResponsiveOptions}
                            autoplayInterval={4000}
                            circular={true}
                          ></ReactCarousel>
                        </div>
                      </div>
                    </>
                  ) : ""}


                </div>
              </div>
              <div className="col-md-9">

                {teamsOrder.length > 0 ? (
                  <>
                    <div className="results-table">
                      <div className="title">
                        <h3>الترتيب</h3>
                      </div>
                      {displayTeamsOrder()}
                    </div>
                  </>
                ) : ''
                }

                {teamsPoints.length > 0 ? (
                  <>
                    <div className="results-table">
                      <div className="title">
                        <h3>النقاط</h3>
                      </div>
                      {displayTeamsPoints()}
                    </div>
                  </>
                ) : ''
                }


                <div className="matches">

                  {displaySchedulerHeader()}


                  <div className="tab-content">
                    {resultData.length > 0 ? (
                      <>
                        {displayScheduler()}
                      </>
                    ) : (
                      <>
                        <div className="emptyData">
                          <img src="/content/images/nodata.png" alt="no data" />
                          <span>لا يوجد نتائج</span>
                        </div>
                      </>
                    )
                    }


                    {/* <p>جدول النتائج يوضع هنا</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // }

}


function mapStateToProps(state: any) {
  return {
    sessons: state.sport.sessons
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getSesson: bindActionCreators(action.getSessonsApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);

