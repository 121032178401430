import React, { useEffect, useState } from "react";
import SideBar from "../../shared/UI-components/sidebar/sidebar";
import { Accordion, AccordionTab } from 'primereact/accordion';
import * as action from "../../../redux/actions/homeActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocViewer, { BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer } from "react-doc-viewer";
import * as api from '../../../api/susfApi';
import { Dialog } from 'primereact/dialog';

function Prints(props: any) {
  const [printsData, setData] = useState<any>([]);
  const [docs, setDocViewer] = useState<any>([]);
  const [displayResponsive, setDisplayResponsive] = useState(false);


  const onHide = () => {
    setDisplayResponsive(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.allPublication || props.allPublication.length <= 0) {
      props.getPublication();
    }
  }, [])

  useEffect(() => {
    setData(props.allPublication ? props.allPublication : []);
  }, [props.allPublication])

  const viewDoc = (attachKey: string, attachName: string) => {
    api.getFile(attachKey, attachName, "publication").then((response) => {
      // setDocViewer(response.data?.url);//[{ uri: response.data?.url }]);
      // setDisplayResponsive(true);
      window.open(response.data?.url,"_self");
    });
  }

  return (
    <>

      <Dialog header="مستعرض الملفات" visible={displayResponsive} draggable={false} maximized={true} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} style={{ width: '75vw' }} >
      <iframe src={docs} className="iframe"></iframe>
        {/* <DocViewer config={{ header: { disableHeader: true, disableFileName: true, retainURLParams: false } }}
          documents={docs} pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer]} style={{ width: '70vw', height: '50vh' }} /> */}
      </Dialog>


      <div className="prints">
        <div className="banner">
          <div className="overlay">
            <h3>المطبوعات</h3>
            <h5>المطبوعات</h5>
          </div>
        </div>
        <div className="page-with-sidebar">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="content">
            <div className="container">
              <Accordion multiple={true}>


                {/* {printsData2 && printsData2.length > 0 ? (
                  <> */}
                {printsData.map((printType: any) => (
                  <AccordionTab header={printType.name} key={printType._id} >
                    <div className="row">
                      {printType.publication.map((print: any) => (
                        <div className="col-sm-12 col-md-12 col-lg-6">
                          <div className="print-box">
                            <div className="info">
                              <h3>
                                {print.name}
                              </h3>
                              <button className="btn" onClick={() => viewDoc(print.attachKey, print.attachName)}>مشاهدة</button>
                            </div>
                            <div className="img">
                              <img src={print.image} alt="laws" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionTab>
                ))}
                {/* </>
                ) : ''
                } */}


                {/* <AccordionTab header="عنوان المطبوعات الاول" >
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab header="عنوان المطبوعات الثاني">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab header="عنوان السؤال الثالث">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="print-box">
                        <div className="info">
                          <h3>
                            دليل المسابقات
                            للطالبات
                          </h3>
                          <button className="btn">مشاهدة</button>
                        </div>
                        <div className="img">
                          <img src="./content/images/laws.png" alt="laws" />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionTab>
               */}

              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


function mapStateToProps(state: any) {
  return {
    allPublication: state.home.allPublication,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getPublication: bindActionCreators(action.getAllPublicationApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Prints);