import React, { useEffect, useState } from "react";
import SideBar from "../../shared/UI-components/sidebar/sidebar";
import * as action from "../../../redux/actions/homeActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

function MediaFile(props: any) {
  const [newsData, setData] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.allMediaCenter || props.allMediaCenter.length <= 0) {
      props.getAllMediaCenter();
    }
  }, [])

  useEffect(() => {
    setData(props.allMediaCenter ? props.allMediaCenter : []);
  }, [props.allMediaCenter])

  return (
    <>
      <div className="mediaFile">
        <div className="banner">
          <div className="overlay">
            <h3>الملف الإعلامي</h3>
            <h5>الملف الإعلامي</h5>
          </div>
        </div>
        <div className="page-with-sidebar">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="content">
            <div className="row">
              {newsData && newsData.length > 0 ? (
                <>
                  {newsData.map((file: any) => (
                    <div className="col-sm-12 col-md-6 col-lg-6" key={file._id}>
                      <div className="file-box">
                        <a href={file.newsLink} target="_blank">
                          <img src="./content/images/icons/ext-link.svg" alt="link" />
                        </a>
                        <img
                          src={file.image}
                          alt="main-img"
                          className="main-img"
                        />
                        <div className="info">
                          <Link to={`/details/${file.id}`} >
                            <h3>{file.title}</h3>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : ''
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


function mapStateToProps(state: any) {
  return {
    allMediaCenter: state.home?.allMediaCenter?.newspaper
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getAllMediaCenter: bindActionCreators(action.getAllMediaCenterApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaFile);