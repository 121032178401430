import React, { useEffect, useState } from "react";
import SideBar from "../../shared/UI-components/sidebar/sidebar";
import ModalVideo from "react-modal-video";
import * as action from "../../../redux/actions/homeActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function Videos(props) {
  const [vidiosData, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!props.allMediaCenter || props.allMediaCenter.length <= 0) {
      props.getAllMediaCenter();
    }
  }, [])

  useEffect(() => {
    setData(props.allMediaCenter ? props.allMediaCenter : []);
  }, [props.allMediaCenter])

  const [isOpen, setisOpen] = useState(false);
  const openModal = () => {
    setisOpen(!isOpen);
  };

  const [videoID, setVideoID] = useState('');

  const playVideo = (id) => {
    setVideoID(id);
    setisOpen(!isOpen);
  }

  return (
    <>
      <div className="videos-page">
        <div className="banner">
          <div className="overlay">
            <h3>الوسائط</h3>
            <h5>الوسائط</h5>
          </div>
        </div>
        <div className="page-with-sidebar">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="content">
            <div className="container">
              <div className="row">
                {vidiosData && vidiosData.length > 0 ? (
                  <>
                    {vidiosData.map(video => (
                      <div className="col-sm-12 col-md-6 col-lg-4" key={video._id}>
                        <div className="media-box" onClick={() => playVideo(video.url)}>
                          <img
                            src={video.image}
                            alt="main-img"
                            className="main-img"
                          />
                          <img
                            src="./content/images/icons/play_outline.svg"
                            alt="play"
                            className="play-img"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : ''}
              </div>
            </div>

          </div>
        </div>
      </div>
      <ModalVideo channel="youtube" isOpen={isOpen} videoId={videoID} onClose={openModal} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    allMediaCenter: state.home?.allMediaCenter?.vidios
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllMediaCenter: bindActionCreators(action.getAllMediaCenterApi, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
